import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { PixStateFacadeService } from '@libs/modules/payment-v2/services/pix-state-facade.service';
import { PaymentInfoActions } from '@libs/store/payment-info';
import { UuidGenerator } from '@libs/utils/uuid-generator/uuid-generator';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions';
import { IResolvedProductRouteInfo } from '@meupatrocinio/resolvers/payment/interfaces/resolved-product-route-info.interface';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'mp-form-pix-payment-v2',
  templateUrl: './form-pix-payment-v2.component.html',
})
export class PixPaymentFormV2Component implements OnInit {
  public copyCode$: Observable<string> = of('');
  public shouldHideQrCode$: Observable<boolean> = of(false);
  public qrCode$: Observable<string> = of('');
  public qrCodeGenerationTimestamp$: Observable<number> = of(0);
  public isLoadingPixQrCode$: Observable<boolean> = of(false);

  @Input() product!: IResolvedProductRouteInfo;

  constructor(
    protected pixStateFacadeService: PixStateFacadeService,
    protected store: Store,
  ) {
    //
  }

  ngOnInit(): void {
    this.isLoadingPixQrCode$ = this.pixStateFacadeService.isPaying$;
    this.copyCode$ = this.pixStateFacadeService.copyCode$;
    this.shouldHideQrCode$ = this.pixStateFacadeService.shouldHideQrCode$;
    this.qrCode$ = this.pixStateFacadeService.qrCode$;
    this.qrCodeGenerationTimestamp$ = this.pixStateFacadeService.qrCodeGenerationTimestamp$;
    this.checkPreviousPix();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { product } = changes;
    if (product && this.product) {
      this.checkPreviousPix();
    }
  }

  public checkPreviousPix() {
    const { productUuid, variantUuid } = this.product;

    const parseJSON = (key: string) => {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    };

    const previousPixData = parseJSON('pix-previous-data');
    const previousPaymentData = parseJSON('payment-wait-list-previous-data');

    if (!previousPixData || !previousPaymentData) {
      return this.pixStateFacadeService.savePaymentData('payment-wait-list-previous-data', {
        productUuid,
        variantUuid,
      });
    }

    const { qrCodeGenerationTimestamp, qrCode, copyCode } = previousPixData;
    const now = Date.now();
    const expirationTime = 15 * 60 * 1000;

    const isSamePayment =
      previousPaymentData.productUuid === productUuid && previousPaymentData.variantUuid === variantUuid;

    const isQrCodeValid = now - qrCodeGenerationTimestamp <= expirationTime;

    if (isQrCodeValid && isSamePayment) {
      this.qrCode$ = of(qrCode);
      this.copyCode$ = of(copyCode);
      this.qrCodeGenerationTimestamp$ = of(qrCodeGenerationTimestamp);
      return;
    }

    this.pixStateFacadeService.removePaymentData('pix-previous-data');
    this.pixStateFacadeService.savePaymentData('payment-wait-list-previous-data', { productUuid, variantUuid });
  }

  public canShowPixPaymentOptionContent(copyCode: string, shouldHideQrCode: boolean) {
    return copyCode === '' && !shouldHideQrCode;
  }

  public canRenderQrCodeCountdown(qrCode: string, copyCode: string) {
    return qrCode !== '' && copyCode !== '';
  }

  public canShowPixQrCodeResult(qrCode: string, copyCode: string, shouldHideQrCode: boolean) {
    return this.canRenderQrCodeCountdown(qrCode, copyCode) || this.canRenderQrCodeExpiredResult(shouldHideQrCode);
  }

  public resetPixData() {
    this.store.dispatch(PaymentInfoActions.resetPixData());
  }

  public makePixPayment(cpf: string) {
    if (!this.product) {
      return;
    }

    const idempotencyKey = UuidGenerator.generate();

    this.store.dispatch(
      ProviderSwitchActions.handlePixPayment({
        paymentData: {
          taxId: cpf,
          product: {
            product_uuid: this.product.productUuid,
            variant_uuid: this.product.variantUuid,
          },
          idempotencyKey,
        },
      }),
    );
  }

  private canRenderQrCodeExpiredResult(shouldHideQrCode: boolean) {
    return shouldHideQrCode;
  }
}
