import { Action, createReducer, on } from '@ngrx/store';

import { RegV5Actions } from '@libs/store/regv5/actions';
import { regV5InitialState } from '@libs/store/regv5/state';

const reducer = createReducer(
  regV5InitialState,
  on(RegV5Actions.saveMarketingParams, (state, { params }) => {
    if (!Object.keys(params).length) {
      return state;
    }

    return {
      ...state,
      payload: {
        ...state.payload,
        cookies: {
          ...params,
        },
      },
    };
  }),
  on(RegV5Actions.handleBasicInfoStep, (state, { birthdate, email, password }) => {
    return {
      ...state,
      payload: {
        ...state.payload,
        birthdate,
        email,
        password,
      },
    };
  }),
  on(RegV5Actions.handleSugarProfileStep, (state, { gender, sexualPreference, sugarRole }) => {
    return {
      ...state,
      payload: {
        ...state.payload,
        sugarRole,
        sexualPreference,
        gender,
      },
    };
  }),
  on(RegV5Actions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving,
    };
  }),
  on(RegV5Actions.handleMiddleStep, (state, { stepData }) => {
    return {
      ...state,
      payload: {
        ...state.payload,
        ...stepData,
      },
    };
  }),
  on(RegV5Actions.setNavigationDirection, (state, { direction }) => {
    return {
      ...state,
      navigationDirection: direction,
    };
  }),
  on(RegV5Actions.setChangeEmailStatus, (state, { status }) => {
    return {
      ...state,
      changeEmailStatus: status,
    };
  }),
  on(RegV5Actions.setNewEmail, (state, { email }) => {
    return {
      ...state,
      payload: {
        ...state.payload,
        email,
      },
    };
  }),
);

export function regV5Reducer(state = regV5InitialState, action: Action) {
  return reducer(state, action);
}
