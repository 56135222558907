import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription, iif, interval, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { ProfileServiceCommon } from '@libs/modules/main/services/profile/profile.service.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { initializeAnalytics } from '@libs/store/analytics/actions';
import { IApplicationState } from '@libs/store/application-state';
import { TokenReceivedAction } from '@libs/store/authentication/actions/token.action';
import { NotificationVerifyPhotoCleanAction } from '@libs/store/notifications/actions/notification-verify-photo-clean.action';
import { RegistrationClearAction } from '@libs/store/registration/actions/registration.action';
import { ClearAllAction } from '@libs/store/ui/actions/clear-all.action';

@Injectable({
  providedIn: 'root',
})
export abstract class AuthenticationServiceCommon implements OnDestroy {
  onLogin$: Subject<never> = new Subject<never>();
  onLogout$: Subject<never> = new Subject<never>();

  protected subscriptions: Subscription[] = [];
  protected user: UserCommon = new UserCommon(0);
  protected isLoggedOut = true;
  protected token = '';
  protected alreadyInitialized = false;

  protected readonly COOKIE_REDIRECT_URL_NAME: string = 'UrlLoggedOut';

  constructor(
    protected store: Store<IApplicationState>,
    protected router: Router,
    protected profileService: ProfileServiceCommon,
  ) {
    //
  }

  public abstract navigateToHome(): void;

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * This is our implementation of the ngOnInit for Services,
   * since Angular doesn't support it.
   *
   * The best way to describe the need for this method is
   * with the following example:
   * - suppose the contents of the method below are in the constructor;
   * - this Common constructor is executed before the children;
   * - cookies service is only defined on the child;
   * - the callback of a store below may be executed before its initialization;
   * - calls a method that depends on the cookies service;
   * - breaks.
   */
  public mpOnInit(): void {
    this.subscriptions.push(
      this.store.select('token').subscribe((data): void => {
        const isTokenValid = data.token && data.token.length > 0;

        if (!isTokenValid) {
          this.isLoggedOut = true;

          return;
        }

        this.isLoggedOut = false;
        this.token = data.token;

        this.profileService.updateSelf();

        if (!this.alreadyInitialized) {
          this.store.dispatch(initializeAnalytics());
          this.alreadyInitialized = true;
        }
      }),
    );

    this.subscriptions.push(
      this.store.select('user').subscribe((user: UserCommon): void => {
        const lastStatus = this.user.status || undefined;
        this.user = Object.assign({}, user);

        if (lastStatus === this.user.status) {
          return;
        }

        if (this.isAuthenticated()) {
          this.onStatusChange();

          return;
        }

        this.onLogout();
      }),
    );

    this.subscriptions.push(
      interval(60000)
        .pipe(mergeMap((value) => iif((): boolean => this.isAuthenticated(), of(value))))
        .subscribe((): void => {
          this.profileService.updateSelf();
        }),
    );
  }

  public abstract onStatusChange(): void;

  protected abstract redirectByStatus(): void;

  public redirectMainWhenUserIsFilled(): void {
    this.subscriptions.push(
      this.store
        .select('token')
        .pipe(
          mergeMap(() => this.store.select('user')),
          filter((user) => UserCommon.isConnected(user)),
        )
        .subscribe((): void => {
          this.navigateToHome();
        }),
    );
  }

  public redirectUserToMain(): void {
    if (this.isAuthenticated()) {
      this.navigateToHome();

      return;
    }

    this.redirectMainWhenUserIsFilled();
  }

  public get(): UserCommon {
    return this.user;
  }

  getToken(): string {
    return this.token;
  }

  public isAtWaitingList(): boolean {
    return (
      (this.user && this.user.status === UserCommon.STATUS_ON_HOLD) || this.user.status === UserCommon.STATUS_PENDING
    );
  }

  public handleExpressApproval(): void {
    if (this.hasRoute('/register/waiting-list')) {
      return;
    }

    this.navigate(['register', 'waiting-list']);
  }

  public navigateToEditProfile() {
    this.router.navigate(['main', 'edit-profile'], this.getNavigationExtras());
  }

  public isProfileEdit(): boolean {
    return this.user && this.user.status === UserCommon.STATUS_PROFILE_EDIT;
  }

  public isActive(): boolean {
    return this.user && this.user.status === UserCommon.STATUS_ACTIVE;
  }

  public isPending(): boolean {
    return this.user !== undefined && this.user.status === UserCommon.STATUS_PENDING;
  }

  public isAuthenticated(): boolean {
    return !this.isLoggedOut;
  }

  public isRestricted(): boolean {
    return this.isAtWaitingList() || this.isProfileEdit();
  }

  public logout(): void {
    this.isLoggedOut = true;
    this.alreadyInitialized = false;
    this.store.dispatch(new ClearAllAction());
  }

  public onLogout(): void {
    this.onLogout$.next();

    if (this.router.url.indexOf('login') !== -1) {
      return;
    }

    this.navigate(['initial', 'login']);
  }

  public isRestrictedOrNotAuthenticated(): boolean {
    return !this.isAuthenticated() || this.isRestricted();
  }

  public setToken(token: string): void {
    this.store.dispatch(
      new TokenReceivedAction({
        token,
      }),
    );
    this.store.dispatch(new NotificationVerifyPhotoCleanAction());
    this.store.dispatch(new RegistrationClearAction());
  }

  abstract onFailedAttemptToAccessURL(url: string): void;

  protected abstract attemptRedirectToFailedURL(): boolean;

  public onNoForcedRouteFound(): void {
    if (!this.canCallDefaultRoute()) {
      return;
    }

    this.redirectUserToMain();
  }

  protected hasRoute(route: string): boolean {
    return this.router.url.indexOf(route) !== -1;
  }

  protected abstract canCallDefaultRoute(): boolean;

  protected navigate(route: string[]): void {
    this.router.navigate(route, this.getNavigationExtras());
  }

  protected abstract hasInitialRoutes(): boolean;

  protected abstract getNavigationExtras(): object;
}
