<div class="generic-header chat-header">
    <div>
        <div class="info">
            <div>
                <mp-actionbar-back-button class="visible-xs visible-sm"></mp-actionbar-back-button>
            </div>
            <div (click)="navigateToProfile()">
                <mp-actionbar-profile-details
                    [profile]="receiver"
                    [canShowBadges]="true"
                    [isFavoriteIconVisible]="true"
                    [isOnlineStatus]="isReceiverOnline()"
                ></mp-actionbar-profile-details>
            </div>
        </div>
        <div class="header-options" #headerOptionsElement>
            <mp-actionbar-badges
                [profile]="receiver"
            ></mp-actionbar-badges>
            <mp-actionbar-three-dots
                *ngIf="threeDotsMenu.length > 0"
                [options]="threeDotsMenu"
                (menuIsVisible)="handleMenuIsVisible($event)"
            ></mp-actionbar-three-dots>
        </div>
    </div>
</div>
