import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { IPaymentData } from '@libs/modules/main/shared/payment-modal-form/payment-data';
import { PaymentHelpersService } from '@libs/modules/payment-v2/services/payment-helpers.service';
import { PaymentModalFormService } from '@meupatrocinio/services/payment-modal-form/payment-modal-form.service';

@Component({
  selector: 'mp-payment-modal-form',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './payment-modal-form.html',
})
export class PaymentModalFormComponent {
  private paymentModalFormService = inject(PaymentModalFormService);
  private paymentHelpersService = inject(PaymentHelpersService);

  @Input() submitButtonTranslation = 'modules.main.pages.payment.pay';
  @Input() showLoading = false;
  @Output() readonly paymentData = new EventEmitter<IPaymentData>();

  public readonly tooltipText = 'modules.main.pages.payment.cc_cvv.tooltip';
  public readonly tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';

  public paymentModalForm = this.paymentModalFormService.getFormGroup();
  public isValidCardNumber = true;
  public isValidDate = true;
  public isValidCVV = true;
  public isValidHolder = true;
  public isValidCPF = true;

  makePayment(): void {
    if (!this.isFormGroupValid()) {
      return;
    }

    this.handlePaymentData();
  }

  isFormGroupValid(): boolean {
    return this.paymentModalFormService.isFormValid();
  }

  isIssuedAbroad(): boolean {
    return this.paymentModalFormService.getIsIssuedAbroad();
  }

  getErrorMessage(controlName: string): string {
    return this.paymentModalFormService.getFormValidationErrosMessage(controlName);
  }

  handleCardNumber(): void {
    this.formatCardNumberValue();
    this.checkCardNumberValidation();
    this.checkCVVValidation();
  }

  formatCardNumberValue(): void {
    this.paymentModalFormService.setCardNumber(
      this.paymentHelpersService.formatNumber(this.paymentModalFormService.getCardNumber()),
    );
  }

  checkCardNumberValidation(): void {
    this.isValidCardNumber =
      this.paymentModalFormService.paymentGroupForm.get('cardNumber').valid ||
      this.paymentModalFormService.isCardNumberPristine();
  }

  checkCVVValidation(): void {
    this.isValidCVV =
      (this.paymentModalFormService.isValidCVV() && !this.paymentModalFormService.groupHasInvalidCVVError()) ||
      this.paymentModalFormService.isPristineCVV();
  }

  checkHolderValidation(): void {
    this.isValidHolder = this.paymentModalFormService.isValidHolder();
  }

  handleDate(): void {
    this.checkDateValidation();
  }

  checkDateValidation(): void {
    this.isValidDate = this.paymentModalFormService.isValidDate();
  }

  handleIsIssuedAbroad(checked: boolean): void {
    this.paymentModalFormService.setIsIssuedAbroad(checked);
    this.paymentModalFormService.handleDefaultCPF(checked);
  }

  handleCPF(): void {
    this.formatCPFValue();
    this.checkCPFValidation();
  }

  formatCPFValue(): void {
    this.paymentModalFormService.setCPF(this.paymentHelpersService.formatNumber(this.paymentModalFormService.getCPF()));
  }

  checkCPFValidation(): void {
    this.isValidCPF = this.paymentModalFormService.isCPFValid();
  }

  handlePaymentData(): void {
    this.paymentData.emit({
      cardNumber: this.paymentModalFormService.getCardNumber(),
      date: this.paymentModalFormService.getDate(),
      cvv: this.paymentModalFormService.getCvv(),
      holder: this.paymentModalFormService.getHolder(),
      cpf: this.paymentModalFormService.getCPF(),
    });
  }
}
