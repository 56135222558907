import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mp-payment-selector-v2',
  templateUrl: './payment-selector-v2.component.html',
  styleUrls: ['./payment-selector-v2.component.scss'],
})
export class PaymentSelectorV2Component {
  @Input() label = '';
  @Input() price = '';
  @Input() id = '';
  @Input() inputName = '';
  @Input() images: string[] = [];
  @Input() isDisabled = false;
  @Output() onClick = new EventEmitter<void>();

  public handleDisableModifierClass() {
    return this.isDisabled ? 'payment-selector--disabled' : '';
  }
}
