import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, throwError } from 'rxjs';

import { LoginComponentCommon } from '@libs/modules/initial/login/login.component.common';
import { UserCommon } from '@libs/shared/user/user.common';
import { AnalyticsActions } from '@libs/store/analytics/actions';
import { IApplicationState } from '@libs/store/application-state';

import { ChangePasswordModalComponent } from '@meupatrocinio/components/change-password-modal/change-password-modal.component';
import { ReactivateModalComponent } from '@meupatrocinio/components/reactivate-modal/reactivate-modal.component';
import { Config } from '@meupatrocinio/config';
import { ForgotPasswordModalComponent } from '@meupatrocinio/modules/initial/shared/forgot-password-modal/forgot-password-modal.component';
import { AdvancedModalService } from '@meupatrocinio/services/advanced-modal.service';
import { AlertService } from '@meupatrocinio/services/alert.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { UserService } from '@meupatrocinio/services/user.service';
import { BusinessCommon } from '@meupatrocinio/utils/business-common';
import { BLURHASH_LOGIN_IMAGE } from '@meupatrocinio/utils/components/blurhash-image/constants';
import { catchError, take, tap } from 'rxjs/operators';

@Component({
  selector: 'mp-login',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './login.html',
})
export class LoginComponent extends LoginComponentCommon {
  public forgotPasswordOpen: boolean;
  public isAttemptingLogin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('formToken', { static: true }) formToken: ElementRef;

  constructor(
    protected auth: AuthenticationService,
    protected userService: UserService,
    public translate: TranslateService,
    public router: Router,
    protected alertService: AlertService,
    protected modal: AdvancedModalService,
    protected store: Store<IApplicationState>,
  ) {
    super(userService);
  }

  submit(): void {
    this.alertService.clear();

    if (!this.validateLogin()) {
      return;
    }

    this.setIsAttemptingLogin(true);
    this.login()
      .pipe(
        take(1),
        tap({
          next: (response: HttpResponse<any>) => {
            if (response.body.message === this.LOGIN_STATUS_DELETED) {
              this.modal.open(ReactivateModalComponent, {
                userDismissable: false,
                data: {
                  token: response.body.token,
                },
              });
              this.setIsAttemptingLogin(false);

              return;
            }

            this.auth.setToken(response.body.token);
          },
        }),
        catchError((error) => {
          this.checkLoginErrors(error);
          this.setIsAttemptingLogin(false);

          return throwError(error);
        }),
      )
      .subscribe();
  }

  protected setIsAttemptingLogin(isAttempting: boolean): void {
    this.isAttemptingLogin$.next(isAttempting);
  }

  validateLogin(): boolean {
    if (!this.loginInfo.username) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.username.missing'));
      this.emailValidateIcon = this.getValidateIcon(false);
      return false;
    }

    if (this.loginInfo.username.search('@') !== -1) {
      if (!UserCommon.isValidEmail(this.loginInfo.username)) {
        this.alertService.error(this.translate.instant('modules.initial.pages.login.username.invalid_email'));
        this.emailValidateIcon = this.getValidateIcon(false);
        return false;
      }
    } else if (!this.loginInfo.isValidUserName()) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.username.invalid_user'));
      this.emailValidateIcon = this.getValidateIcon(false);
      return false;
    }

    if (!this.loginInfo.isValidPassword()) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.password.missing'));
      return false;
    }

    if (!this.isOnline()) {
      this.alertService.error(this.translate.instant('login.offline.error'));
      this.store.dispatch(
        AnalyticsActions.pushEvent({
          eventName: 'login_connection_error',
        }),
      );

      return false;
    }

    return true;
  }

  checkLoginErrors(response: HttpErrorResponse): void {
    if (response.statusText === '' && (response.status === 200 || !response.status)) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.offline'));
      return;
    }

    if (response.status === 403) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.version_error_web'));
      return;
    }

    if (response.status === 429) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.throttle'));
      return;
    }

    if (response.status === 503) {
      this.router.navigate(['maintenance'], { replaceUrl: true });
      return;
    }

    if (response.status !== 401) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.unknown_error'));
      return;
    }

    const error = response.error.error;

    if (error === 'could_not_create_token') {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.could_not_create_token'));
      return;
    }

    if (error === 'need.verify') {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.need_verify'));
      return;
    }

    if (error === this.LOGIN_STATUS_SUSPENDED) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.status_suspended'));
      return;
    }

    if (error === this.LOGIN_STATUS_PERM_DELETED) {
      this.alertService.error(this.translate.instant('modules.initial.pages.login.status_temporary_deleted'));
      return;
    }

    if (error === this.LOGIN_ONLY_CHANGE_PASSWORD) {
      this.modal.open(ChangePasswordModalComponent, {
        userDismissable: false,
      });

      return;
    }

    this.alertService.error(this.translate.instant('modules.initial.pages.login.auth_error'));
  }

  forgotPassword(): void {
    this.forgotPasswordOpen = true;
    this.modal.open(ForgotPasswordModalComponent, {
      data: {
        closeCallback: (): void => {
          this.forgotPasswordOpen = false;
        },
      },
    });
  }

  isOnline(): boolean {
    return navigator.onLine;
  }

  public getCnpj(): string {
    return BusinessCommon.CNPJ;
  }

  public getLoginBannerByName(imageName: string, imageFormat: string) {
    return `${Config.imagesCdnUrl}login/${imageName}.${imageFormat}`;
  }

  public getBannerUrl() {
    const viewportWidth = window.innerWidth;

    return viewportWidth < 992
      ? this.getLoginBannerByName('login-bg-sm', 'webp')
      : this.getLoginBannerByName('login-bg-md', 'webp');
  }

  public getBannerBlurhash() {
    return BLURHASH_LOGIN_IMAGE;
  }
}
