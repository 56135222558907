import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { IBasicInfoStep } from '@libs/store/regv5/interfaces/basic-info.interface';
import { IMiddleStepAction } from '@libs/store/regv5/interfaces/middle-step-action.interface';
import { ISugarProfileStep } from '@libs/store/regv5/interfaces/sugar-profile-step.interface';
import { IUploadPicture } from '@libs/store/regv5/interfaces/upload-picture.interface';
import { ChangeEmailStatus } from '@libs/store/regv5/types/change-email-status.type';
import { NavigationDirection } from '@libs/store/regv5/types/navigation-direction.type';

export const RegV5Actions = createActionGroup({
  source: 'Reg V5',
  events: {
    'Get Marketing Params': emptyProps(),
    'Save Marketing Params': props<{ params: { [name: string]: string } }>(),
    'Handle Basic Info Step': props<IBasicInfoStep>(),
    'Handle Sugar Profile Step': props<ISugarProfileStep>(),
    'Start Registration': props<ISugarProfileStep>(),
    'Start Registration Success': emptyProps(),
    'Handle Middle Step': props<IMiddleStepAction>(),
    'Finish Registration': emptyProps(),
    'Suspend Registration': emptyProps(),
    'Set Is Saving': props<{ isSaving: boolean }>(),
    'Navigate Back': props<{ currentStepName: string }>(),
    'Navigate Next': props<{ currentStepName: string }>(),
    'Set Navigation Direction': props<{ direction: NavigationDirection }>(),
    'Request Email Change': props<{ email: string }>(),
    'Resend Verification Email': props<{ email: string }>(),
    'Save Pictures': props<{ formData: FormData }>(),
    'Set Change Email Status': props<{ status: ChangeEmailStatus }>(),
    'Navigate To Step': props<{ stepName: string }>(),
    'Upload Photos': props<{ data: FormData }>(),
    'Create User': props<{ profilePhotos: IUploadPicture[] }>(),
    'Handle User by Sugar Role': emptyProps(),
    'Handle Finish for Daddy': emptyProps(),
    'Handle Finish for Baby': emptyProps(),
    'Set New Email': props<{ email: string }>(),
    'Registration Failed': emptyProps(),
    'Check If Should Have Photos': emptyProps(),
    'Handle Profile Picture Step': emptyProps(),
    'Go Back to Profile Pictures': emptyProps(),
  },
});
