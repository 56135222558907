import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';

import { CookiesActions } from '@libs/store/cookies/';

import { IUpdateMarketingCookiesAction } from '@meupatrocinio/modules/marketing-cookies/interfaces/update-marketing-cookies-action.interface';
import { MarketingCookiesService } from '@meupatrocinio/modules/marketing-cookies/services/marketing-cookies.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class MarketingCookiesEffects {
  private actions$ = inject(Actions);
  private marketingCookiesService = inject(MarketingCookiesService);
  private authenticationService = inject(AuthenticationService);

  updateMarketingCookies$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(CookiesActions.updateMarketingCookies),
        concatMap((action: IUpdateMarketingCookiesAction): Observable<Action> => {
          return this.marketingCookiesService
            .updateMarketingCookies$(action.utm_source, action.utm_campaign, action.gclid, action.vcid)
            .pipe(
              concatMap((): Observable<Action> => EMPTY),
              catchError((): Observable<Action> => EMPTY),
            );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  getMarketingCookiesAndUpdateOnLogin$: Observable<void> = createEffect(
    (): Observable<void> =>
      this.authenticationService.onLogin$.pipe(
        tap({
          next: (): void => {
            this.marketingCookiesService.getMarketingCookiesAndDispatchUpdateAction();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );
}
