import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthenticationServiceCommon } from '@libs/services/authentication/authentication.service.common';
import { IApplicationState } from '@libs/store/application-state';
import { UiActions } from '@libs/store/new-ui';

import { InitialNavigationActions } from '@meupatrocinio/effects/initial-navigation/actions';
import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';
import { MarketingCookiesService } from '@meupatrocinio/modules/marketing-cookies/services/marketing-cookies.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';

import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthenticationService extends AuthenticationServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected cookies: CookieService,
    protected router: Router,
    protected profileService: ProfileService,
    protected route: ActivatedRoute,
    protected location: Location,
    protected abTestsLoaderService: ABTestsLoaderService,
    protected marketingCookiesService: MarketingCookiesService,
  ) {
    super(store, router, profileService);
  }

  public mpOnInit(): void {
    this.checkToken();

    this.handleMarketingQueryParams();

    super.mpOnInit();
  }

  public handleMarketingQueryParams() {
    const params = this.router.parseUrl(window.location.search).queryParams;
    const isRegistrationV5 = this.location.path().includes('register');

    if (isRegistrationV5) {
      return;
    }

    this.marketingCookiesService.saveDefaultMarketingCookies(params);
  }

  public navigateToHome() {
    this.navigate(['main', 'home']);
  }

  onFailedAttemptToAccessURL(url: string): void {
    this.cookies.set(this.COOKIE_REDIRECT_URL_NAME, url, 7, '/');
  }

  public attemptRedirectToFailedURL(): boolean {
    const url: string = this.cookies.get(this.COOKIE_REDIRECT_URL_NAME);
    const openedTab: string = 'openedTab=true';

    if (!url || url.indexOf('/') !== 0) {
      return false;
    }

    this.cookies.delete(this.COOKIE_REDIRECT_URL_NAME, '/');

    const delimiter = url.includes('?') ? '&' : '?';

    this.router.navigateByUrl(url + delimiter + openedTab, this.getNavigationExtras());

    return true;
  }

  protected hasRoute(route: string): boolean {
    return window.location.pathname.indexOf(route) !== -1;
  }

  protected canCallDefaultRoute(): boolean {
    return (
      window.location.pathname === '/' ||
      this.hasRoute('/register/waiting-list') ||
      this.hasRoute('/register/finishing') ||
      this.hasRoute('/edit-profile') ||
      this.hasRoute('/cadastro') ||
      this.hasInitialRoutes()
    );
  }

  protected getNavigationExtras(): NavigationExtras {
    return {
      replaceUrl: true,
      queryParamsHandling: 'merge',
      preserveFragment: true,
    };
  }

  protected hasInitialRoutes(): boolean {
    return (
      this.hasRoute('initial/login') ||
      this.hasRoute('initial/email-verify') ||
      this.hasRoute('initial/change-password')
    );
  }

  protected checkToken(): void {
    const tokenMatches: string[] = window.location.href.match(/token=([^&]*)/g);

    if (tokenMatches !== null && tokenMatches !== undefined && tokenMatches.length >= 1) {
      const token: string = tokenMatches[0].replace(/token=/, '');
      this.logout();
      this.setToken(token);
      this.router.navigate(['.'], { relativeTo: this.route });
    }
  }

  protected handleRedirectToSearch(): void {
    this.navigateToSearch();
    this.replaceNavigationHistoryWithHome();
    this.store.dispatch(UiActions.setHasRedirectedOnSession());
  }

  protected navigateToSearch(): void {
    this.router.navigate(['main', 'search']);
  }

  protected replaceNavigationHistoryWithHome(): void {
    this.location.replaceState('/main/home');
  }

  protected redirectByStatus(): void {
    this.store.dispatch(InitialNavigationActions.redirectByStatus());
  }

  public onStatusChange(): void {
    this.onLogin$.next();

    this.redirectByStatus();
  }
}
