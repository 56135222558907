import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[emailInput]',
})
export class EmailInputDirective {
  private readonly allowedCharactersRegex = /[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~.@]/;

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (!this.allowedCharactersRegex.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const pastedText = event.clipboardData?.getData('text') || '';
    const sanitizedText = pastedText
      .split('')
      .filter((character) => this.allowedCharactersRegex.test(character))
      .join('');
    const emailInput = event.target as HTMLInputElement;

    emailInput.value = sanitizedText;
  }
}
