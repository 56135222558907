import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@meupatrocinio/guards/auth/auth-guard.service';
import { LoginGuard } from '@meupatrocinio/guards/login/login.guard';
import { FrictionlessRegistrationComponent } from '@meupatrocinio/modules/frictionless-registration/frictionless-registration.component';
import { InitialChangePasswordComponent } from '@meupatrocinio/modules/initial/pages/change-password/change-password.component';
import { EmailUnsubscribeComponent } from '@meupatrocinio/modules/initial/pages/email-unsubscribe/email-unsubscribe.component';
import { EmailVerifyComponent } from '@meupatrocinio/modules/initial/pages/email-verify/email-verify.component';
import { HashExpiredComponent } from '@meupatrocinio/modules/initial/pages/hash-expired/hash-expired.component';
import { HashUnavailableComponent } from '@meupatrocinio/modules/initial/pages/hash-unavailable/hash-unavailable.component';
import { LoginComponent } from '@meupatrocinio/modules/initial/pages/login/login.component';
import { NewPasswordComponent } from '@meupatrocinio/modules/initial/pages/new-password/new-password.component';
import { MaintenanceComponent } from '@meupatrocinio/modules/main/pages/maintenance/maintenance.component';
import { NotFoundComponent } from '@meupatrocinio/modules/not-found.component';
import { REG_V5_MAIN_ROUTE_NAME } from '@meupatrocinio/modules/reg-v5/constants/step-data';
import { RegV5Guard } from '@meupatrocinio/modules/reg-v5/guards/reg-v5.guard';
import { RegV5Module } from '@meupatrocinio/modules/reg-v5/reg-v5.module';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'initial/login',
    pathMatch: 'full',
  },
  {
    path: 'initial/login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'initial/email-verify/:hash',
    component: EmailVerifyComponent,
  },
  {
    path: 'initial/email-unsubscribe/:key',
    component: EmailUnsubscribeComponent,
  },
  {
    path: 'initial/change-password/:hash',
    component: InitialChangePasswordComponent,
  },
  {
    path: 'initial/hash-unavailable',
    component: HashUnavailableComponent,
  },
  {
    path: 'initial/hash-expired',
    component: HashExpiredComponent,
  },
  {
    path: 'initial/new-password',
    component: NewPasswordComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'main',
    canActivate: [AuthGuard],
    loadChildren: async (): Promise<any> =>
      import('@meupatrocinio/modules/main/main.module').then((module): typeof module.MainModule => module.MainModule),
  },
  {
    path: REG_V5_MAIN_ROUTE_NAME,
    canLoad: [RegV5Guard],
    loadChildren: async (): Promise<typeof RegV5Module> =>
      import('@meupatrocinio/modules/reg-v5/reg-v5.module').then(
        (module): typeof module.RegV5Module => module.RegV5Module,
      ),
  },
  {
    path: 'cadastro',
    component: FrictionlessRegistrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cadastro/:referral',
    component: FrictionlessRegistrationComponent,
    canActivate: [AuthGuard],
  },
];

export const navigatableComponents = [
  EmailVerifyComponent,
  EmailUnsubscribeComponent,
  FrictionlessRegistrationComponent,
  HashExpiredComponent,
  HashUnavailableComponent,
  InitialChangePasswordComponent,
  LoginComponent,
  MaintenanceComponent,
  NewPasswordComponent,
  NotFoundComponent,
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(
      [
        ...routes,
        {
          path: '**',
          component: NotFoundComponent,
        },
      ],
      {
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules,
      },
    ),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AppRoutingWebModule {
  //
}
