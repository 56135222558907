import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { PaymentMethod, PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { PixStateFacadeService } from '@libs/modules/payment-v2/services/pix-state-facade.service';
import { IProfile } from '@libs/shared/profile/profile';
import { UserCommon } from '@libs/shared/user/user.common';
import { selectHasBoletoPaymentType, selectHasCreditCardPaymentType } from '@libs/store/payment/selectors';

import { PaymentInfoSelectors } from '@libs/store/payment-info';
import { Config } from '@meupatrocinio/config';

@Component({
  selector: 'mp-payment-method-selector',
  templateUrl: './payment-method-selector.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodSelectorComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private pixStateFacadeService = inject(PixStateFacadeService);

  @Input() isTrial = false;
  @Input() user: UserCommon;
  @Input() isExpressApproval = false;
  @Input() currentPaymentMethod = PaymentMethods.PAYMENT_CREDIT_CARD;
  @Output() currentPaymentMethodChange: EventEmitter<string> = new EventEmitter<string>();

  public readonly PAYMENT_CARD: PaymentMethod = PaymentMethods.PAYMENT_CREDIT_CARD;
  public readonly PAYMENT_BOLETO: PaymentMethod = PaymentMethods.PAYMENT_BOLETO;
  public readonly PAYMENT_PIX: PaymentMethod = PaymentMethods.PAYMENT_PIX;
  public isPixPaymentEnabledMembership: boolean = Config.isPixPaymentEnabledMembership;
  public isPixPaymentEnabledExpressApproval: boolean = Config.isPixPaymentEnabledExpressApproval;
  public hasPixTest$: Observable<boolean> = of(false);
  public isPaying$ = this.store.pipe(select(PaymentInfoSelectors.selectIsPaying));

  protected subscriptions: Subscription[] = [];

  ngOnInit() {
    this.handleExpressApprovalPaymentType();
    this.handleDefaultPaymentMethod();
    this.handleTrialPaymentType();

    this.hasPixTest$ = this.pixStateFacadeService.hasPixTest$;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  protected handleDefaultPaymentMethod() {
    this.subscriptions.push(
      combineLatest([this.canShowCardPaymentMethod(this.user), this.canShowBoletoPaymentMethod()]).subscribe({
        next: ([canShowCardPaymentMethod, canShowBoletoPaymentMethod]) => {
          this.handleSetPaymentMethodOnInitialization(canShowCardPaymentMethod, canShowBoletoPaymentMethod);
        },
      }),
    );
  }

  public canShowCardPaymentMethod(user: IProfile) {
    return this.store.pipe(select(selectHasCreditCardPaymentType)).pipe(
      concatMap((hasCreditCardPaymentType) => {
        return of(hasCreditCardPaymentType && this.isUserElectiveToCardPayment(user));
      }),
    );
  }

  protected isUserElectiveToCardPayment(user: IProfile | undefined) {
    return user !== undefined && !this.isBabyMaleExpressApproval(user);
  }

  protected isBabyMaleExpressApproval(user: IProfile) {
    return this.isExpressApproval && UserCommon.isBaby(user) && UserCommon.isMale(user);
  }

  public canShowBoletoPaymentMethod() {
    return this.store.pipe(select(selectHasBoletoPaymentType)).pipe(
      concatMap((hasBoletoPayment) => {
        return of(hasBoletoPayment && !this.isTrial);
      }),
    );
  }

  public shouldShowPixPayment(hasPixPaymentMembershipTest: boolean) {
    if (!this.isExpressApproval) {
      return this.canShowPixOnMembership(hasPixPaymentMembershipTest);
    }

    return this.canShowPixOnExpressApproval();
  }

  public canShowPixOnMembership(hasPixPaymentMembershipTest: boolean) {
    return this.isPixPaymentEnabledMembership && hasPixPaymentMembershipTest && !this.isTrial;
  }

  public canShowPixOnExpressApproval() {
    return this.isPixPaymentEnabledExpressApproval;
  }

  protected handleExpressApprovalPaymentType() {
    if (this.isUserElectiveToCardPayment(this.user)) {
      return;
    }

    this.changePaymentType(PaymentMethods.PAYMENT_BOLETO);
  }

  public changePaymentType(paymentOption: string) {
    if (PaymentMethods.getAvailablePaymentMethods()[paymentOption] === undefined) {
      return;
    }

    this.currentPaymentMethod = PaymentMethods.getAvailablePaymentMethods()[paymentOption];
    this.currentPaymentMethodChange.emit(this.currentPaymentMethod);
  }

  protected handleTrialPaymentType() {
    if (!this.isTrial) {
      return;
    }

    this.changePaymentType(PaymentMethods.PAYMENT_CREDIT_CARD);
  }

  protected handleSetPaymentMethodOnInitialization(canShowCardPaymentMethod, canShowBoletoPayment) {
    if (!canShowCardPaymentMethod && !canShowBoletoPayment) {
      this.changePaymentType(PaymentMethods.PAYMENT_NONE);

      return;
    }

    if (canShowCardPaymentMethod) {
      this.changePaymentType(PaymentMethods.PAYMENT_CREDIT_CARD);

      return;
    }

    this.changePaymentType(PaymentMethods.PAYMENT_BOLETO);
  }

  public isCardPayment() {
    return this.currentPaymentMethod === PaymentMethods.PAYMENT_CREDIT_CARD;
  }

  public isPixPayment() {
    return this.currentPaymentMethod === PaymentMethods.PAYMENT_PIX;
  }

  public isBoletoPayment() {
    return this.currentPaymentMethod === PaymentMethods.PAYMENT_BOLETO;
  }

  public getPaymentBrands(paymentMethod: string) {
    if (paymentMethod === PaymentMethods.PAYMENT_CREDIT_CARD) {
      return Config.paymentBrands.map((brand: string) => {
        return {
          name: brand.toLowerCase(),
          format: '.gif',
        };
      });
    }

    if (paymentMethod === PaymentMethods.PAYMENT_PIX) {
      return [
        {
          name: PaymentMethods.PAYMENT_PIX,
          format: '.png',
        },
      ];
    }

    return [
      {
        name: PaymentMethods.PAYMENT_BOLETO,
        format: '.gif',
      },
    ];
  }
}
