import { Injectable, inject } from '@angular/core';
import { IPaymentErrorActionPayload } from '@libs/effects/analytics/interfaces/payment-error-action-payload.interface';
import { IBoostGtmData } from '@libs/shared/boost/interfaces/boost-gtm-data.interface';
import {
  AnalyticsActions,
  destroyAnalytics,
  getGtmStats,
  informBoostDataOnGtm,
  initializeAnalytics,
  onPaymentSuccess,
} from '@libs/store/analytics/actions';
import { BoostProductSelectors } from '@libs/store/boost-product';
import { MembershipSelectors } from '@libs/store/membership';
import { PaymentInfoActions } from '@libs/store/payment-info';
import { AnalyticsService } from '@meupatrocinio/services/analytics/analytics.service';
import { UserService } from '@meupatrocinio/services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class AnalyticsEffects {
  protected actions$ = inject(Actions);
  protected userService = inject(UserService);
  protected analyticsService = inject(AnalyticsService);
  protected store = inject(Store);

  initializeAnalytics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeAnalytics),
        tap((): void => {
          this.userService.handleAnalyticsMeData();
        }),
        map(() => getGtmStats()),
      ),
    { useEffectsErrorHandler: true },
  );

  destroyingAnalytics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(destroyAnalytics),
        tap((): void => {
          this.userService.destroyGtmSubject();
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  gettingGtmStats$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getGtmStats),
        tap((): void => {
          this.userService.getGtmStats();
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  onPaymentSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(onPaymentSuccess),
        withLatestFrom(
          this.store.pipe(select(MembershipSelectors.selectLatestPaidMembership)),
          this.store.pipe(select(BoostProductSelectors.selectProductUuid)),
        ),
        tap(([actionData, membershipId, boostProductUuid]) => {
          this.analyticsService.onPayment(actionData.paymentInfo, membershipId, boostProductUuid);
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  onFailedPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handlePaymentErrorStatus),
        tap({
          next: ({ price }: IPaymentErrorActionPayload): void => {
            this.analyticsService.onFailedPayment(price);
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  informBoostDataOnGtm = createEffect(
    () =>
      this.actions$.pipe(
        ofType(informBoostDataOnGtm),
        tap({
          next: ({ boostData }: { boostData: IBoostGtmData }): void => {
            this.analyticsService.onBoostDataUpdateTriggered(boostData);
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  pushEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.pushEvent),
        tap({
          next: ({ eventName, parameters }) => {
            this.analyticsService.push({
              event: eventName,
              ...parameters,
            });
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );
}
