import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, select } from '@ngrx/store';
import { interval, of } from 'rxjs';
import { concatMap, delay, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { ProviderV2Actions } from '@libs/modules/payment-v2/actions';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';
import { BoostProductActions, BoostProductSelectors } from '@libs/store/boost-product';
import { IPaymentInfo, PaymentInfoActions, PaymentInfoStatus, PaymentSelectors } from '@libs/store/payment-info';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';

import { PaymentInfoService } from '@meupatrocinio/modules/main/services/payment/payment-info.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class PaymentV2Effects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private paymentInfoService = inject(PaymentInfoService);
  private authenticationService = inject(AuthenticationService);
  private router = inject(Router);

  private readonly PAYMENT_STATUS_POLLING_INTERVAL: number = 4000 as const;
  private readonly QR_CODE_EXPIRATION_TIME_MS = 15 * 60 * 1000;

  checkPaymentStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProviderV2Actions.checkPaymentStatus),
        withLatestFrom(this.store.select(PaymentSelectors.selectCurrentPayment)),
        concatMap(([action, currentPayment]) => {
          return this.paymentInfoService.getPaymentStatus(currentPayment.idempotencyKey).pipe(
            exponentialBackoff(),
            concatMap((response: IAuthResponse<{ status: string }>) => {
              if (response.data.status === 'pending') {
                return of(action).pipe(delay(this.PAYMENT_STATUS_POLLING_INTERVAL));
              }

              const paymentInfo: IPaymentInfo = {
                ...currentPayment.paymentInfo,
                status: response.data.status as PaymentInfoStatus,
              };

              return of(
                PaymentInfoActions.handleUpdatedResponse({
                  updatedPaymentInfo: paymentInfo,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handlePixSuccessPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handlePixSuccessPayment),
        concatLatestFrom(() => this.store.pipe(select(BoostProductSelectors.selectProductUuid))),
        map(([{ productUuid }, boostProductUuid]) => {
          const action =
            boostProductUuid === productUuid
              ? PaymentInfoActions.handleBoostPixSuccessPayment()
              : PaymentInfoActions.handlePlansPixSuccessPayment();

          return action;
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleBoostPixSuccessPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handleBoostPixSuccessPayment),
        map(() => {
          return BoostProductActions.boostPackagePaymentSuccess();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handlePlansPixSuccessPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handlePlansPixSuccessPayment),
        map(() => {
          return PaymentInfoActions.setPaymentStatus({
            paymentStatus: PaymentStatus.PAYMENT_OK,
          });
        }),
        tap({
          next: () => {
            this.store.dispatch(PaymentInfoActions.navigateAfterPayment());
          },
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleQrCodeExpiration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.setQrCode),
        map(() => {
          return PaymentInfoActions.setQrCodeGenerationTimestamp({
            qrCodeGenerationTimestamp: Date.now(),
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  watchForQrCodeExpiration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.setQrCodeGenerationTimestamp),
        switchMap(({ qrCodeGenerationTimestamp }) => {
          return interval(1000).pipe(
            filter(() => Date.now() - qrCodeGenerationTimestamp >= this.QR_CODE_EXPIRATION_TIME_MS),
            take(1),
            map(() => {
              this.resetQrCode();
              this.paymentInfoService.terminateCheck();

              return PaymentInfoActions.setShouldHideQrCode({
                shouldHideQrCode: true,
              });
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  resetPixDataOnNewAppLoad$ = createEffect(
    () =>
      this.authenticationService.onLogin$.pipe(
        exhaustMap(() => {
          return of(PaymentInfoActions.resetPixData());
        }),
        take(1),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  resetPixData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.resetPixData),
        map(() => {
          this.resetQrCode();
          this.paymentInfoService.terminateCheck();

          return PaymentInfoActions.setShouldHideQrCode({
            shouldHideQrCode: false,
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  onQrCodeGeneration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.onQrCodeGeneration),
        tap({
          next: ({ productUuid }) => {
            this.paymentInfoService.listenToPixPayment(productUuid);
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  navigateAfterPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.navigateAfterPayment),
        withLatestFrom(this.store.pipe(select(PaymentSelectors.selectCurrentPayment))),
        tap({
          next: ([_, currentPayment]) => {
            this.navigateAfterPayment(currentPayment.paymentInfo);
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  private resetQrCode() {
    this.store.dispatch(PaymentInfoActions.resetQrCode());
    this.store.dispatch(PaymentInfoActions.resetCopyCode());
  }

  private navigateAfterPayment(paymentInfo: IPaymentInfo): void {
    if (!this.isExpressApproval(paymentInfo.product_uuid)) {
      this.router.navigate(['main', 'home'], { replaceUrl: true });

      return;
    }

    this.router.navigate(['register', 'waiting-list'], {
      replaceUrl: true,
    });
    this.store.dispatch(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus: PaymentStatus.PAYMENT_NONE,
      }),
    );
  }

  private isExpressApproval(productUuid: string) {
    return productUuid === ProductVariantIdentifier.EXPRESS_APPROVAL;
  }
}
