import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, Type, ViewContainerRef, inject } from '@angular/core';

import { IModalViewOptions } from '@libs/components/modal-view/interface/modal-view-options';
import { ModalViewServiceCommon } from '@libs/components/modal-view/modal-view.service.common';
import { MODAL_VIEW_OPTIONS } from '@libs/components/modal-view/modal-view.tokens';

import { ModalViewReference } from '@meupatrocinio/modules/main/shared/modal-view/modal-view-reference';
import { ModalViewComponent } from '@meupatrocinio/modules/main/shared/modal-view/modal-view.component';
import { InjectComponentService } from '@meupatrocinio/services/inject-component.service';
import { ModalViewOverlayConfig } from '@meupatrocinio/services/modal-view/modal-view-overlay-config';

@Injectable({
  providedIn: 'root',
})
export class ModalViewService extends ModalViewServiceCommon {
  private overlay = inject(Overlay);
  private injector = inject(Injector);
  private injectComponentService = inject(InjectComponentService);

  public overlayReference: OverlayRef = this.overlay.create();
  public modalViewReference: ModalViewReference = new ModalViewReference(this.overlayReference);

  public open(component: Type<unknown>, options: IModalViewOptions): void {
    this.modalViewReference.diposeModalView();
    this.overlayReference = this.overlay.create(new ModalViewOverlayConfig(this.overlay).getOverlayConfig(options));
    this.modalViewReference = new ModalViewReference(this.overlayReference);
    this.attachModalView(ModalViewComponent, component, options);
  }

  public close(): void {
    this.modalViewReference.close();
  }

  protected attachModalView(
    modalViewComponent: ComponentType<ModalViewComponent>,
    component: Type<unknown>,
    options: IModalViewOptions,
  ): void {
    this.modalViewReference.componentInstance = this.overlayReference.attach(
      new ComponentPortal(modalViewComponent, null, this.createPortalInjector(options)),
    ).instance;

    const { modalViewContent }: { modalViewContent: ViewContainerRef } = this.modalViewReference.componentInstance;

    this.injectComponentService.inject(component, modalViewContent, this.createPortalInjector(options));
  }

  protected createPortalInjector(options: IModalViewOptions): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MODAL_VIEW_OPTIONS,
          useValue: options,
        },
      ],
    });
  }
}
