import { Injectable, inject } from '@angular/core';
import { MembershipActions, MembershipSelectors } from '@libs/store/membership';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class MembershipEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);

  paidMembershipChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MembershipActions.latestPaidMembershipChange),
        concatMap((action) => {
          return of(action).pipe(
            withLatestFrom(this.store.pipe(select(MembershipSelectors.selectLatestPaidMembership))),
          );
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );
}
