import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Output } from '@angular/core';

import { ActionbarThreeDotsComponentCommon } from '@libs/modules/shared/actionbar/actionbar-three-dots/actionbar-three-dots.component.common';
import { IThreeDotsMenuItem } from '@libs/shared/interfaces/three-dots-menu-item.interface';
import { Tick } from '@libs/utils/timeout-typings';

@Component({
  selector: 'mp-actionbar-three-dots',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './actionbar-three-dots.html',
})
export class ActionbarThreeDotsComponent extends ActionbarThreeDotsComponentCommon {
  protected menuTimeout: Tick | undefined;
  @Output() public menuIsVisible = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const dropdown = target.closest('.dropdown');
    const isInsideDropdown = dropdown && dropdown.closest('mp-actionbar-three-dots');

    if (!isInsideDropdown && !this.menuHidden) {
      this.closeMenu();
    }
  }

  private closeMenu(): void {
    this.menuHidden = true;
    this.menuIsVisible.emit(false);
  }

  toggleMenu(): void {
    // Clear any existing timeout when manually toggling
    clearTimeout(this.menuTimeout);
    this.menuHidden = !this.menuHidden;
    this.menuIsVisible.emit(!this.menuHidden);
  }

  handleOptionClicked(option: IThreeDotsMenuItem): void {
    if (option.closeMenuOnClick) {
      this.toggleMenu();
    }

    option.action();
  }
}
