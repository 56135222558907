import { createAction, props } from '@ngrx/store';

import { IProfile } from '@libs/shared/profile/profile';

enum Actions {
  MANY_PROFILES_RECEIVED = '[Profiles] - Many Profiles Received',
  ONE_PROFILE_RECEIVED = '[Profiles] - One Profile Received',
  RESET_FAVORITED_PROFILES = '[Profiles] - Reset Received Profiles',
  REMOVE_PROFILE = '[Profiles] - Remove Profile',
  GET_FAVORITES_FROM_PROFILES = '[Profiles] - Get Favorites From Profiles',
}

export const manyProfilesReceived = createAction(Actions.MANY_PROFILES_RECEIVED, props<{ profiles: IProfile[] }>());

export const oneProfileReceived = createAction(Actions.ONE_PROFILE_RECEIVED, props<{ profile: IProfile }>());

export const resetProfileFavorited = createAction(Actions.RESET_FAVORITED_PROFILES, props<{ profileId: number }>());

export const removeProfile = createAction(Actions.REMOVE_PROFILE, props<{ profileId: number }>());

export const getFavoritesFromProfiles = createAction(Actions.GET_FAVORITES_FROM_PROFILES);
