import { Injectable } from '@angular/core';
import { Config } from '@meupatrocinio/config';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCatalogService {
  constructor(private readonly authHttp: AuthHttpService) {
    //
  }

  public getMembershipProducts() {
    const endpoint = `${Config.serverIp}v2/catalog/membership-packages`;

    return this.authHttp.get(endpoint);
  }

  public getExpressApprovalProduct() {
    const endpoint = `${Config.serverIp}v2/catalog/express-approval-packages`;

    return this.authHttp.get(endpoint);
  }

  public getBoostProducts() {
    const endpoint = `${Config.serverIp}v2/catalog/boost-packages`;

    return this.authHttp.get(endpoint);
  }

  public getPlanByUuid(uuid: string) {
    const endpoint = `${Config.serverIp}payment/v2/plans/${uuid}`;

    return this.authHttp.get(endpoint);
  }
}
