import { IProductV2State, IProductVariant } from '@libs/modules/product-v2/interfaces/product-catalog.interface';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { ProductIdentifier } from '@libs/shared/product/product-identifier.enum';
import { selectLastMembershipPayment } from '@libs/store/payment-info/selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectRegV5Feature = createFeatureSelector<IProductV2State>('productV2');

export const ProductSelectors = {
  selectMembershipProducts: createSelector(selectRegV5Feature, (state) => {
    return state.products?.[ProductIdentifier.MEMBERSHIP_PRODUCT_UUID] ?? [];
  }),
  selectExpressApprovalProduct: createSelector(selectRegV5Feature, (state): IProductVariant | undefined => {
    return state.products?.[ProductIdentifier.EXPRESS_APPROVAL_PRODUCT_UUID]?.[0] ?? undefined;
  }),
};

export const ProductUtilitarySelectors = {
  selectProductByMembershipAndPeriod: (membershipTypeId: MembershipType, period: number) =>
    createSelector(ProductSelectors.selectMembershipProducts, (products) => {
      return products?.find(
        (paymentOption) => paymentOption.membership_type_id === membershipTypeId && paymentOption.period === period,
      );
    }),
  selectPeriodByAmountPaid: createSelector(
    ProductSelectors.selectMembershipProducts,
    selectLastMembershipPayment,
    (membershipProducts, lastPayment) => {
      if (!lastPayment?.subtotal_amount || !membershipProducts || !membershipProducts.length) {
        return 0;
      }

      const lastPaymentOption = membershipProducts.find((paymentOption) => {
        return paymentOption.price === lastPayment.subtotal_amount;
      });

      return lastPaymentOption?.period ?? 0;
    },
  ),
  hasAvailableProducts: createSelector(ProductSelectors.selectMembershipProducts, (membershipProducts) => {
    if (!membershipProducts) {
      return false;
    }

    return membershipProducts.length > 0;
  }),
};
