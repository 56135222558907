import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { UserCommon } from '@libs/shared/user/user.common';

import { UpgradeAccountScreenActions } from '@libs/store/upgrade-account';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { UpsellService } from '@meupatrocinio/services/upsell/upsell.service';

@Component({
  selector: 'mp-upsell',
  templateUrl: './upsell.html',
  styleUrls: ['./upsell.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsellComponent {
  constructor(
    protected authenticationService: AuthenticationService,
    protected translateService: TranslateService,
    protected router: Router,
    protected store: Store,
    protected upsellService: UpsellService,
  ) {
    //
  }

  protected get user(): UserCommon {
    return this.authenticationService.get();
  }

  public hideUpsell(): void {
    this.upsellService.hideUpsell();
  }

  public navigateToUpgradeAccount(): void {
    this.store.dispatch(UpgradeAccountScreenActions.navigateToUpgradeAccount());
  }

  public getUpsellPlanBadge(): string {
    return this.upsellService.getUpsellPlanBadge(this.user);
  }

  public getUpsellText(): string {
    return this.upsellService.getUpsellText(this.user);
  }

  public getTextStyle(): string {
    const baseClass: string = 'upsell-card__content__text';

    if (!MembershipCommon.isFree(this.user.membership_type_id)) {
      return `${baseClass} ${baseClass}--elite`;
    }

    return baseClass;
  }
}
