import { Location } from '@angular/common';
import { Directive, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';

import { ISubmenuItems } from '@libs/shared/interfaces/submenu-items.interface';

@Directive()
export abstract class SubmenuV2ComponentCommon implements OnInit {
  protected location = inject(Location);

  @Input() public submenuItems: ISubmenuItems[] = [];
  @Output() readonly listRefresher: EventEmitter<number> = new EventEmitter<number>();

  public index = 0;
  protected subscriptions: Subscription[] = [];
  protected readonly LIST_ITEM_NOT_FOUND: string = 'Trying to navigate to an undefined item';

  abstract updateOnNavigation(): void;

  abstract navigateTo(route: any[], extras?: NavigationExtras): void;

  ngOnInit(): void {
    this.updateSubmenuItems();
  }

  updateSubmenuItems(): void {
    for (const key of this.submenuItems) {
      if (this.location.path(true).indexOf(key.routeName) !== -1) {
        this.index = key.index;
        return;
      }
    }
  }

  navigateToIndex(index: number): void {
    this.index = index;

    const item: ISubmenuItems | undefined = this.submenuItems.find(
      (menuItem: ISubmenuItems): boolean => menuItem.index === index,
    );

    if (item === undefined) {
      throw new Error(this.LIST_ITEM_NOT_FOUND);
    }

    this.navigateTo(item.route, item.extras);
  }

  refreshList(index: number): void {
    this.listRefresher.emit(index);
  }

  getActiveClass(itemIndex: number): string {
    if (this.index === itemIndex) {
      return 'active';
    }

    return '';
  }
}
