import { createAction, createActionGroup, props } from '@ngrx/store';

import { IBoostGtmData } from '@libs/shared/boost/interfaces/boost-gtm-data.interface';
import { IPaymentInfo } from '@libs/store/payment-info';
import { AnalyticsEventName } from '@meupatrocinio/services/analytics/types/analytics-event-name.type';

enum Actions {
  INITIALIZE_ANALYTICS = '[ANALYTICS/API] - Initialized',
  DESTROY_ANALYTICS = '[ANALYTICS/API] - Destroyed',
  GET_GTM_STATS = '[ANALYTICS/API] - Get Gtm Stats',
  ON_PAYMENT_SUCCESS = '[ANALYTICS/API] - On Payment Success',
  INFORM_BOOST_DATA_ON_GTM = '[ANALYTICS / GTM] - Inform Boost Data On GTM',
  PUSH_EVENT = '[ANALYTICS / GTM] - Push Event',
}

export const initializeAnalytics = createAction(Actions.INITIALIZE_ANALYTICS);

export const destroyAnalytics = createAction(Actions.DESTROY_ANALYTICS);

export const getGtmStats = createAction(Actions.GET_GTM_STATS);

export const onPaymentSuccess = createAction(Actions.ON_PAYMENT_SUCCESS, props<{ paymentInfo: IPaymentInfo }>());

export const informBoostDataOnGtm = createAction(
  Actions.INFORM_BOOST_DATA_ON_GTM,
  props<{ boostData: IBoostGtmData }>(),
);

export const AnalyticsActions = createActionGroup({
  source: 'Analytics',
  events: {
    'Push Event': props<{ eventName: AnalyticsEventName; parameters?: { profileId: number } }>(),
  },
});
