<div class="outside-page container">
    <div class="row">
        <div class="card-container col-md-6 col-lg-5">
            <div class="login-card">
                <div class="login-card__mp-logo">
                    <a href="https://www.meupatrocinio.com/">
                        <img src="/assets/img/meu-patrocinio-logo-v02.svg">
                    </a>
                </div>
              <form
                    class="page-form"
                    (submit)="submit()"
                >
                    <div class="form-field">
                        <label for="name">
                            {{ 'modules.initial.pages.login.username' | translate }}
                        </label>
                        <input
                            id="login-field-email"
                            placeholder="{{ 'common.fill_out_field' | translate }}"
                            type="email"
                            name="email"
                            [(ngModel)]="loginInfo.username"
                            [ngModelOptions]="{standalone: true}"
                        >
                    </div>
                    <div class="form-field">
                        <label for="password">{{ 'common.password' | translate }}</label>
                        <input
                            id="login-field-password"
                            placeholder="{{ 'common.fill_out_field' | translate }}"
                            type="password"
                            name="password"
                            [(ngModel)]="loginInfo.password"
                            [ngModelOptions]="{standalone: true}"
                        >
                    </div>
                    <mp-alert *ngIf="!forgotPasswordOpen"></mp-alert>
                    <div class="form-action flex">
                        <div
                            id="login-button-forgot-password"
                            class="form-action__forget-pass"
                        >
                            <a (click)="forgotPassword()">
                                {{ 'modules.initial.pages.login.forgot_password' | translate }}
                            </a>
                        </div>
                        <div class="form-action__login-button">
                            <button
                                id="login-button-login"
                                class="mp-main-button bt-md bt-shadow !tw-bg-primary-dark"
                                type="submit"
                                [disabled]="isAttemptingLogin$ | async"
                            >
                                {{ 'common.login' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
                <p class="login-card__register-text">
                    {{ 'modules.initial.pages.login.sign_up_blurb' | translate }}
                </p>
                <a
                    id="login-button-registration"
                    class="login-card__register-action"
                    [routerLink]="['/cadastro']"
                >
                    {{ 'modules.initial.pages.login.sign_up_now' | translate }}
                </a>
                <form
                    class="hidden"
                    #expressApproval
                    method="POST"
                    data-skadate="member/"
                >
                    <input
                        #formToken
                        name="authorization"
                    >
                </form>
            </div>
            <div class="business-info small">
                {{ getCnpj() }}
            </div>
        </div>
        <div class="banner-container col-md-6 col-lg-5">
            <div class="login-banner">
              <app-blurhash-image
                [imageUrl]="getBannerUrl()"
                [hash]="getBannerBlurhash()"
              ></app-blurhash-image>
            </div>
        </div>
    </div>
    <div
        class="modal modal-loading"
        *ngIf="isAttemptingLogin$ | async"
    >
        <mp-loading></mp-loading>
    </div>
</div>
