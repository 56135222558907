import { IProductVariant } from '@libs/modules/product-v2/interfaces/product-catalog.interface';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { ProductIdentifier } from '@libs/shared/product/product-identifier.enum';
import { ProductV2Actions } from '@libs/store/product-v2/actions';
import { productV2InitialState } from '@libs/store/product-v2/state';
import { createReducer, on } from '@ngrx/store';

export const ProductV2Reducer = createReducer(
  productV2InitialState,
  on(ProductV2Actions.saveMembershipProducts, (state, { products, isBaby = false }) => {
    if (!products || !products.length) {
      return {
        ...state,
      };
    }

    return {
      ...state,
      products: {
        ...state.products,
        [ProductIdentifier.MEMBERSHIP_PRODUCT_UUID]: membershipProductAdapter(products, isBaby),
      },
    };
  }),
  on(ProductV2Actions.saveAlreadyFormattedProducts, (state, { paymentOptions }) => {
    return {
      ...state,
      products: {
        ...state.products,
        [ProductIdentifier.MEMBERSHIP_PRODUCT_UUID]: [...paymentOptions],
      },
    };
  }),
  on(ProductV2Actions.saveExpressApprovalProduct, (state, { products }) => {
    return {
      ...state,
      products: {
        ...state.products,
        [ProductIdentifier.EXPRESS_APPROVAL_PRODUCT_UUID]: [...products],
      },
    };
  }),
);

function membershipProductAdapter(products: IProductVariant[], isBaby: boolean) {
  const membership = {
    Premium: isBaby ? MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM : MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM,
    Elite: MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE,
  };

  return products.map((product): IPaymentOption => {
    const oneInstallmentOption = product.installments['1'];
    const price = oneInstallmentOption ? oneInstallmentOption.amount / 100 : 0;
    const isThreeMonthsPlan = product.period === 3;
    const totalByMonth = product.period > 0 ? Math.round(price / product.period) : 0;

    return {
      membership_type_id: membership[product.typePlan] ?? 0,
      period: product.period,
      price,
      units: product.unit,
      discount: (product.percentageDiscount ?? 0) / 100,
      preferred_plan: isThreeMonthsPlan,
      totalByMonth,
      uuid: product.uuid,
    };
  });
}
