<div class="more-actions flex">
    <div
        class="dropdown"
        [ngClass]="{'open': (menuHidden === false)}"
    >
        <button
            class="btn btn-default dropdown-toggle"
            type="button"
            id="headerDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="true"
            (click)="toggleMenu()"
        >
            <i
                class="material-icons"
                aria-hidden="true"
            >
                &#xE5D4;
            </i>
        </button>
        <ul
            class="dropdown-menu"
            aria-labelledby="headerDropdown"
        >
            <ng-container *ngFor="let option of options">
                <li
                    class="divider"
                    role="separator"
                    *ngIf="option.hasSeparator"
                ></li>
                <li class="dropdown-menu--option-list">
                    <a
                        class="dropdown-menu--option-content"
                        (click)="handleOptionClicked(option)"
                    >
                        <span
                            class="dropdown-menu__text-action"
                            [ngClass]="getExtraTextStyleClasses(option)"
                        >
                            {{ option.label | translate }}
                        </span>
                        <i
                            class="dropdown-menu__icon material-icons"
                            aria-hidden="true"
                            *ngIf="hasIcon(option)"
                            [innerHTML]="option.icon"
                            [ngClass]="getExtraIconStyleClasses(option)"
                        ></i>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>
