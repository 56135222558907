import { ISetDefaultSelectedPlansActionPayload } from '@libs/store/upgrade-account/interfaces/set-default-selected-plans.interface';
import { createAction, props } from '@ngrx/store';

enum Actions {
  NAVIGATE_TO_UPGRADE_ACCOUNT = '[Upgrade Account] - Navigate To Upgrade Account',
  HANDLE_UPGRADE_ACCOUNT_ACCESS = '[Upgrade Account] - Handle Upgrade Account Access',
  SET_IS_RESOLVING_VISIBLE_PLANS = '[Upgrade Account] - Set Has Resolved Visible Plans',
  SET_SELECTED_PLAN = '[Upgrade Account] - Set Selected Plan',
  SET_DEFAULT_SELECTED_PLANS = '[Upgrade Account] - Set Default Selected Plans',
}

export const navigateToUpgradeAccount = createAction(Actions.NAVIGATE_TO_UPGRADE_ACCOUNT);

export const handleUpgradeAccountAccess = createAction(Actions.HANDLE_UPGRADE_ACCOUNT_ACCESS);

export const setIsResolvingVisiblePlans = createAction(
  Actions.SET_IS_RESOLVING_VISIBLE_PLANS,
  props<{ isResolvingVisiblePlans: boolean }>(),
);

export const setSelectedPlan = createAction(
  Actions.SET_SELECTED_PLAN,
  props<{
    planUuid: string;
    membershipTypeId: number;
  }>(),
);

export const setDefaultSelectedPlans = createAction(
  Actions.SET_DEFAULT_SELECTED_PLANS,
  props<ISetDefaultSelectedPlansActionPayload>(),
);
