import { IProductVariant } from '@libs/modules/product-v2/interfaces/product-catalog.interface';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ProductV2Actions = createActionGroup({
  source: 'Product V2',
  events: {
    'Fetch Membership Products': props<{ overrideCache?: boolean }>(),
    'Fetch Specific Membership Product': props<{ uuid: string }>(),
    'Save Membership Products': props<{ products: IProductVariant[]; isBaby: boolean }>(),
    'Save Already Formatted Products': props<{ paymentOptions: IPaymentOption[] }>(),
    'Fetch Express Approval Product': emptyProps(),
    'Save Express Approval Product': props<{ uuid: string; products: IProductVariant[] }>(),
  },
});
