<div class="logo-container">
    <a
        *ngIf="hasLoggedIn()"
        [routerLink]="['/main', 'home']"
    >
        <img src="/assets/img/meu-patrocinio-logo.svg">
    </a>
    <img
        *ngIf="!hasLoggedIn()"
        src="/assets/img/meu-patrocinio-logo.svg"
    >
</div>
