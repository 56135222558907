import { Injectable } from '@angular/core';

import * as CPF from 'gerador-validador-cpf';
import { MoipValidator } from 'moip-sdk-js';

@Injectable({
  providedIn: 'root',
})
export class PaymentHelpersService {
  public formatNumber(value: string): string {
    return value.replace(/\D+/g, '');
  }

  public formatCurrency(value: string): string {
    return value.replace(/\./, ',');
  }

  public getCardBrand(cardNumber: string): string {
    const brand = MoipValidator.cardType(cardNumber);

    if (brand === null) {
      return '';
    }

    return brand['brand'];
  }

  public isValidCardInput(cardNumber: string): boolean {
    return this.validInput(cardNumber);
  }

  public validInput(field: string): boolean {
    return this.exist(field) && !this.containWhiteSpace(field);
  }

  public exist(atribute: any): boolean {
    return atribute !== null && atribute !== undefined && atribute !== '';
  }

  public containWhiteSpace(data: string): boolean {
    return /\s/.test(data);
  }

  public isValidCVV(cvv: string, cardNumber: string): boolean {
    return MoipValidator.isSecurityCodeValid(cardNumber, cvv);
  }

  public isValidHolder(holder: string): boolean {
    return this.exist(holder);
  }

  public isValidCPFInput(cpf: string): boolean {
    return /^\d{11}$/.test(cpf);
  }

  public isValidCPFField(cpf: string): boolean {
    return CPF.validate(cpf);
  }
}
