<mp-actionbar-logo-mp></mp-actionbar-logo-mp>
<div class="flex-container">
    <mp-user-menu-items
        *ngIf="showMenuItems()"
        [isSelectedForSearchV2]="isSelectedForSearchV2"
    ></mp-user-menu-items>
    @if (!(isNativeApp$ | async)) {
        <mp-subscribed-status [tinyBadges]="true"></mp-subscribed-status>
        <mp-actionbar-user-image></mp-actionbar-user-image>
    }
</div>
