import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ITrial } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';
import { IPaymentInfo } from '@libs/store/payment-info';
import { selectLastMembershipPayment } from '@libs/store/payment-info/selectors';
import { ITrialState } from '@libs/store/trial/interfaces/trial-state.interface';
import { TrialAdapter } from '@libs/store/trial/state';

const selectFeature = createFeatureSelector<ITrialState>('storeTrials');

export const selectAll = createSelector(selectFeature, TrialAdapter.getSelectors().selectAll);

export const selectIds = createSelector(selectFeature, TrialAdapter.getSelectors().selectIds);

export const selectTrialPeriodByAmountPaid = createSelector(
  selectAll,
  selectLastMembershipPayment,
  (allTrials: ITrial[], lastPayment: IPaymentInfo): number => {
    if (!lastPayment || !lastPayment.subtotal_amount) {
      return 0;
    }

    return Trial.getLastTrialPeriod(lastPayment.subtotal_amount, allTrials);
  },
);
