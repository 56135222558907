<div
  class="tw-p-6 tw-rounded-md tw-mb-8"
  [ngClass]="handleAlertStyle()"
  role="alert"
  #alert
>
  <div class="tw-flex tw-place-content-between">
    @if (title !== '') {
      <div class="tw-flex">
        <p class="tw-font-bold tw-m-0">
          {{ title }}
        </p>
      </div>
    }
    @if (hasCloseButton) {
      <button (click)="close.emit()">
        <span class="material-icons tw-text-red-900 tw-text-base">
          &#XE5CD;
        </span>
      </button>
    }
  </div>
  <ng-content></ng-content>
</div>
