import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';

import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { PaymentActions } from '@libs/store/payment';
import { PaymentSelectors } from '@libs/store/payment-info';

import { Config } from '@meupatrocinio/config';
import { NetcoreFacadeService } from '@meupatrocinio/infra/netcore/services/netcore-facade.service';
import { CREDIT_CARD_BRAND_DETECTOR_TOKEN } from '@meupatrocinio/modules/payment-v2/credit-card-brand-detector/injection-token/credit-card-brand-detector-service-injection-token';
import { PaymentFlowHandlersService } from '@meupatrocinio/modules/payment-v2/payment-flow-handlers/payment-flow-handlers.service';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions';
import { AllCashV2Actions } from '@meupatrocinio/modules/payment-v2/providers/allcash-v2/actions';
import { PagSeguroV2Actions } from '@meupatrocinio/modules/payment-v2/providers/pagseguro-v2/actions';

@Injectable()
export class ProviderSwitchEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private creditCardBrandDetectorService = inject(CREDIT_CARD_BRAND_DETECTOR_TOKEN);
  private paymentFlowHandlersService = inject(PaymentFlowHandlersService);
  private netcoreFacadeService = inject(NetcoreFacadeService);

  prepareCreditCardPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProviderSwitchActions.prepareCreditCardPayment),
        map(({ paymentData, productDescription, idempotencyKey }) => {
          if (
            !paymentData.purchaseInfo ||
            !paymentData.purchaseInfo.product ||
            !paymentData.purchaseInfo.product.product_uuid ||
            !paymentData.purchaseInfo.product.variant_uuid ||
            !paymentData.purchaseInfo.subtotalAmount ||
            !idempotencyKey
          ) {
            throw new Error('Purchase info is missing: productUuid, variantUuid, or subtotalAmount.');
          }

          this.store.dispatch(
            PaymentActions.storeCurrentPayment({
              currentPayment: {
                idempotencyKey,
                paymentInfo: {
                  product_uuid: paymentData.purchaseInfo.product.product_uuid,
                  variant_uuid: paymentData.purchaseInfo.product.variant_uuid,
                  subtotal_amount: paymentData.purchaseInfo.subtotalAmount,
                },
              },
            }),
          );

          return ProviderSwitchActions.handleCreditCardPayment({
            paymentData,
            productDescription,
          });
        }),
        catchError((error) => {
          if (Config.showLogs.device) {
            console.error('Error preparing credit card payment:', error);
          }

          return this.paymentFlowHandlersService.clientErrorBeforePayment();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  handleCreditCardPayment$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProviderSwitchActions.handleCreditCardPayment),
        withLatestFrom(this.store.pipe(select(PaymentSelectors.selectSelectedProvider))),
        map(([{ paymentData, productDescription }, selectedProvider]) => {
          const cardBrand = this.creditCardBrandDetectorService.getCardBrand(paymentData.cardInfo.cardNumber);
          const payload = {
            paymentData: {
              ...paymentData,
              cardBrand,
            },
            productDescription,
          };

          this.netcoreFacadeService.subscriptionPurchase(paymentData.purchaseInfo.totalAmount, 'credit_card');

          if (selectedProvider === PaymentCommon.PROVIDER_ALLCASH) {
            return AllCashV2Actions.handleCreditCardPayment({
              ...payload,
            });
          }

          return PagSeguroV2Actions.handleCreditCardPayment({
            ...payload,
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  handleBoletoPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProviderSwitchActions.handleBoletoPayment),
        withLatestFrom(this.store.pipe(select(PaymentSelectors.selectSelectedProvider))),
        map(([{ paymentData }, assignedProvider]) => {
          this.netcoreFacadeService.subscriptionPurchase(paymentData.totalPrice, 'boleto');

          if (assignedProvider === PaymentCommon.PROVIDER_ALLCASH) {
            return AllCashV2Actions.createBoletoPayment({ paymentData });
          }

          return PagSeguroV2Actions.createBoletoPayment({ paymentData });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handlePixPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProviderSwitchActions.handlePixPayment),
        map(({ paymentData }) => {
          return PagSeguroV2Actions.createPixPayment({
            paymentData,
          });
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );
}
