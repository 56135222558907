import { Injectable, inject } from '@angular/core';
import { EXPIRED_TOKEN_ACTION } from '@libs/store/expired-token/expired-token.action';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { Actions, ofType } from '@ngrx/effects';
import { createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class ExpiredTokenEffects {
  private actions$ = inject(Actions);
  private auth = inject(AuthenticationService);

  expiredToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EXPIRED_TOKEN_ACTION),
        tap(() => {
          this.auth.logout();
        }),
      ),
    { dispatch: false },
  );
}
