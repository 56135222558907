export const BLURHASH_LOGIN_IMAGE = 'U79Zi#-A0064.8NGIB-W01Io~W=|56X8-:Vs';

export const BLURHASH_DEFAULT_PROFILE_WOMAN = 'U5A,zkxu00D%~qof00t7WBM{IU%Mt7%MWBD%';
export const BLURHASH_DEFAULT_PROFILE_MAN = 'U5A,zkxu00D%~qof00t7WBM{IU%Mt7%MWBD%';
export const BLURHASH_DEFAULT_QR_CODE = 'eBMQ*JRj_3fQ_3xuj[j[ayj[~qofRjayWBj[fQWBofj[_3j[ofWBRj';

export const RANDOM_PROFILE_PLACEHOLDERS = [
  'UIKwUz-A1jE*00IU5;M{~qM{adoLxDae$*V@',
  'U9CrsL^+000Kx+-UWI0#0$S6~A-S=_NG9u%2',
  'U8Ed@z}r0}=x9s0#E1$i0~xu={xa0L=e=x9]',
  'UIA,2kFv0z~At7S2fixZE2xG$jEMNGoLs:W;',
  'UIEf1oInT0%2~VInOE%1?HR%xut7%LWXt7M{',
  'UCGkakYiGu}8_49a%MRO0~8_RRE*I:acxutl',
  'UaJHadogx]Vq.TR*jYr;O]s;V@tPogjEwutQ',
  'U8HUOTt-~p1%[95W4;0L0L#jxBIoyCV@oLNH',
  'UHIWZGCR$i~80MIU0gI]OX5T$z$eIo=_^i-o',
  'UAHUhB?H00Io00V?%h4-I9s:I9_4IT?b%NMx',
  'UADSRL^+?wOsE1DORPxtysE1s9so?b9v%M%2',
  'UAHBYqVr00_M01ofIoNH0fkC^PV@9uM{?Hah',
  'UDE_?czn1LHr3Z9sRh-5gPV[;0Iq%i-5M{Na',
  'UTINHe9u?Gof~BR*R+ofx[aee.S4xtn%NHf+',
  'UmGvbUS2*0xu%$aKogt7oyads,ozWBjYayWC',
  'ULHeID0W%%b|t-OwtSxt-.sAIpNG%LIUsmXA',
  'UMIW.f9F?bEf%gv~NxW;~VMx%2-UxuM|%1t7',
];
