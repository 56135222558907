import { Directive, ElementRef, HostListener, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[contactPhoneMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactPhoneMaskDirective),
      multi: true,
    },
  ],
})
export class ContactPhoneMaskDirective {
  private onChange: (value: string) => void;
  private onTouched: () => void;

  private readonly PLACEHOLDER = '(XX) XXXXX-XXXX';
  private readonly DDD_CODE_LENGTH = 2;
  private readonly FIRST_PART_LENGTH = 7;
  private readonly TOTAL_LENGTH = 11;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.renderer.setAttribute(this.el.nativeElement, 'placeholder', this.PLACEHOLDER);
  }

  private applyFormat(value: string): string {
    const cleaned = value.replace(/\D/g, '').substring(0, this.TOTAL_LENGTH);
    const length = cleaned.length;

    if (length <= this.DDD_CODE_LENGTH) {
      return `(${cleaned}`;
    }
    if (length <= this.FIRST_PART_LENGTH) {
      return `(${cleaned.substring(0, 2)}) ${cleaned.substring(2)}`;
    }

    return `(${cleaned.substring(0, 2)}) ${cleaned.substring(2, 7)}-${cleaned.substring(7)}`;
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    let value: string = event.target['value'];
    value = value.replace(/\D/g, '');

    if (isNaN(Number(value))) {
      value = '';
    }

    const formattedValue = this.applyFormat(value);

    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);

    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: string) {
    const formattedValue = value ? this.applyFormat(value) : '';
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('blur')
  onBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
