import { Injectable, inject } from '@angular/core';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class EffectInitializer {
  private actions$ = inject(Actions);
  private authenticationService = inject(AuthenticationService);

  initializer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap((): void => {
          this.authenticationService.mpOnInit();
        }),
      ),
    { dispatch: false },
  );
}
