import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function contactPhoneValidator(controlName: string, form: UntypedFormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const hasDigitNone = control.value[2] === '9' && form.get(controlName).value[2] === '9';

    const validations = {
      requiredNine: !hasDigitNone,
      phoneAreNotTheSame: control.value !== form.get(controlName).value,
    };

    const hasError = Object.values(validations).some((value) => value !== false);

    if (hasError) {
      return validations;
    }

    form.get(controlName).setErrors(null);

    return null;
  };
}
