import { createAction, props } from '@ngrx/store';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IPaymentInfo } from '@libs/store/payment-info/interface';
import { IAccessToCheckoutPayload } from '@libs/store/payment-info/interfaces/access-to-checkout-payload.interface';

enum Actions {
  FETCH_PAYMENT_HISTORY = '[PaymentInfo] - Fetch Payment History',
  UPSERT_PAYMENT_INFO = '[PaymentInfo] - Upsert PaymentInfo',
  SET_PAYMENT_STATUS = '[PaymentInfo] - Set Payment Status',
  CHECK_PAYMENT_UPDATED_STATUS = '[PaymentInfo] - Check Updated PaymentInfo',
  HANDLE_UPDATED_RESPONSE = '[PaymentInfo] - Handle Updated Response',
  PAYMENT_HANDLE_ERROR = '[PaymentInfo] - Handle Payment Error',
  SET_IS_PAYING = '[PaymentInfo] - Set Is Paying',
  HANDLE_ACCESS_TO_CHECKOUT = '[PaymentInfo] - Handle Access to Checkout',
  HANDLE_ACCESS_TO_TRIAL_CHECKOUT = '[PaymentInfo] - Handle Access to Trial checkout',
  NAVIGATE_AFTER_RESOLVING_PLANS = '[PaymentInfo] - Navigate After Resolving Plans',
  SET_HAS_RESOLVED_PLANS = '[PaymentInfo] - Set Has Resolved Plans',
  NAVIGATE_AFTER_PAYMENT = '[PaymentInfo] - Navigate After Pagseguro Payment',
  UPDATE_USER_AND_LOAD_BOOST_PACKAGES = '[PaymentInfo] - Update User and Load Boost Packages',
  SET_BOLETO_URL = '[PaymentInfo] - Set Boleto URL',
  RESET_BOLETO_URL = '[PaymentInfo] - Reset Boleto URL',
  LOAD_PAYMENT_PROVIDER = '[PaymentInfo] - Load Payment Provider',
  SET_QR_CODE = '[PaymentInfo] - Set QR Code',
  RESET_QR_CODE = '[PaymentInfo] - Reset QR Code',
  SET_COPY_CODE = '[PaymentInfo] - Set Copy Code',
  RESET_COPY_CODE = '[PaymentInfo] - Reset Copy Code',
  SET_IS_SHOWING_QR_CODE = '[PaymentInfo] - Set Is Showing QR Code',
  RESET_PIX_DATA = '[PaymentInfo] - Reset Pix Data',
  SET_QR_CODE_GENERATION_TIMESTAMP = '[PaymentInfo] - Set QR Code Generation Timestamp',
  ON_QR_CODE_GENERATION = '[PaymentInfo] - On QR Code Generation',
  HANDLE_PIX_SUCCESS_PAYMENT = '[PaymentInfo] - Handle Pix Success Payment',
  SET_HAS_PIX_TEST = '[PaymentInfo] - Set Has Pix Test',
  HANDLE_BOOST_PIX_SUCCESS_PAYMENT = '[PaymentInfo] - Handle Boost Pix Success Payment',
  HANDLE_PLANS_PIX_SUCCESS_PAYMENT = '[PaymentInfo] - Handle Plans Pix Success Payment',
  HANDLE_PIX_FOR_FAILED_PAYMENT = '[PaymentInfo] - Handle Pix For Failed Payment',
  SET_SHOW_PIX_ALTERNATIVE_PAYMENT = '[PaymentInfo] - Set Show Pix Alternative Payment',
  CHECK_PIX_AVAILABILITY = '[PaymentInfo] - Check Pix Availability',
}

export const fetchPaymentHistory = createAction(Actions.FETCH_PAYMENT_HISTORY);

export const upsertPaymentInfo = createAction(Actions.UPSERT_PAYMENT_INFO, props<{ paymentInfo: IPaymentInfo[] }>());

export const handleUpdatedResponse = createAction(
  Actions.HANDLE_UPDATED_RESPONSE,
  props<{ updatedPaymentInfo: IPaymentInfo }>(),
);

export const setPaymentStatus = createAction(Actions.SET_PAYMENT_STATUS, props<{ paymentStatus: PaymentStatus }>());

export const handlePaymentErrorStatus = createAction(Actions.PAYMENT_HANDLE_ERROR, props<{ price: number }>());

export const setIsPaying = createAction(Actions.SET_IS_PAYING, props<{ isPaying: boolean }>());

export const handleAccessToCheckout = createAction(
  Actions.HANDLE_ACCESS_TO_CHECKOUT,
  props<IAccessToCheckoutPayload>(),
);

export const handleAccessToTrialCheckout = createAction(Actions.HANDLE_ACCESS_TO_TRIAL_CHECKOUT);

export const navigateAfterResolvingPlans = createAction(
  Actions.NAVIGATE_AFTER_RESOLVING_PLANS,
  props<IAccessToCheckoutPayload>(),
);

export const setHasResolvedPlans = createAction(Actions.SET_HAS_RESOLVED_PLANS, props<{ hasResolvedPlans: boolean }>());

export const navigateAfterPayment = createAction(Actions.NAVIGATE_AFTER_PAYMENT);

export const updateUserAndLoadBoostPackages = createAction(
  Actions.UPDATE_USER_AND_LOAD_BOOST_PACKAGES,
  props<{ latestPayment: IPaymentInfo }>(),
);

export const setBoletoUrl = createAction(Actions.SET_BOLETO_URL, props<{ boletoUrl: string }>());

export const resetBoletoUrl = createAction(Actions.RESET_BOLETO_URL);

export const loadPaymentProvider = createAction(Actions.LOAD_PAYMENT_PROVIDER);

export const setQrCode = createAction(Actions.SET_QR_CODE, props<{ qrCode: string }>());

export const resetQrCode = createAction(Actions.RESET_QR_CODE);

export const setCopyCode = createAction(Actions.SET_COPY_CODE, props<{ copyCode: string }>());

export const resetCopyCode = createAction(Actions.RESET_COPY_CODE);

export const setShouldHideQrCode = createAction(Actions.SET_IS_SHOWING_QR_CODE, props<{ shouldHideQrCode: boolean }>());

export const setQrCodeGenerationTimestamp = createAction(
  Actions.SET_QR_CODE_GENERATION_TIMESTAMP,
  props<{ qrCodeGenerationTimestamp: number }>(),
);

export const resetPixData = createAction(Actions.RESET_PIX_DATA);

export const onQrCodeGeneration = createAction(Actions.ON_QR_CODE_GENERATION, props<{ productUuid: string }>());

export const handlePixSuccessPayment = createAction(
  Actions.HANDLE_PIX_SUCCESS_PAYMENT,
  props<{ productUuid: string }>(),
);

export const handleBoostPixSuccessPayment = createAction(Actions.HANDLE_BOOST_PIX_SUCCESS_PAYMENT);

export const handlePlansPixSuccessPayment = createAction(Actions.HANDLE_PLANS_PIX_SUCCESS_PAYMENT);

export const handlePixForFailedPayment = createAction(
  Actions.HANDLE_PIX_FOR_FAILED_PAYMENT,
  props<{ updatedPaymentInfo: IPaymentInfo }>(),
);

export const checkPixAvailability = createAction(Actions.CHECK_PIX_AVAILABILITY);

export const setShowPixAlternativePayment = createAction(
  Actions.SET_SHOW_PIX_ALTERNATIVE_PAYMENT,
  props<{ showPixAlternativePayment: boolean }>(),
);

export const setHasPixTest = createAction(Actions.SET_HAS_PIX_TEST, props<{ hasPixTest: boolean }>());
