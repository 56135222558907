import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store, select } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IPaymentOption } from '@libs/shared/payment-option/payment-option';
import { ProductPaths } from '@libs/shared/product/product-paths.enum';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';
import { onPaymentSuccess } from '@libs/store/analytics/actions';
import { BoostProductActions, BoostProductSelectors } from '@libs/store/boost-product';
import { ConversationActions } from '@libs/store/conversations';
import { MembershipActions } from '@libs/store/membership';
import { MessageActions } from '@libs/store/messages';
import { IPaymentInfo, PaymentInfoActions, PaymentInfoSelectors } from '@libs/store/payment-info';
import { IPaymentProvider } from '@libs/store/payment/interfaces/payment-provider';
import { ProductV2Actions } from '@libs/store/product-v2/actions';
import { ProductSelectors } from '@libs/store/product-v2/selectors';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';
import { HAS_ACCESSED_FORBIDDEN_CHECKOUT_QUERY_PARAM_NAME } from '@meupatrocinio/effects/payment/constants/constants';
import { PaymentInfoService } from '@meupatrocinio/modules/main/services/payment/payment-info.service';
import { ProductRouteInfoService } from '@meupatrocinio/modules/payment-v2/product-route-info/product-route-info.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { BoostProductService } from '@meupatrocinio/services/boost-product/boost-product.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { TrialService } from '@meupatrocinio/services/trial/trial.service';

import { SpecialProductsEligibility } from '@libs/modules/product-v2/services/special-products-eligibility.service';
import { PlanAPlusEligibility } from '@libs/modules/product-v2/strategies/special-products/plan-a-plus-eligibility';

@Injectable()
export class PaymentInfoEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private paymentInfoService = inject(PaymentInfoService);
  private router = inject(Router);
  private authenticationService = inject(AuthenticationService);
  private boostProductService = inject(BoostProductService);
  private trialService = inject(TrialService);
  private productRouteInfoService = inject(ProductRouteInfoService);
  private modalService = inject(ModalService);
  private profileService = inject(ProfileService);

  private readonly RETRY_ACTION_DELAY = 1000 as const;
  private planAPlusEligibility = new SpecialProductsEligibility(PlanAPlusEligibility.getStrategy());

  fetchPaymentHistory$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.fetchPaymentHistory),
        withLatestFrom(this.store.pipe(select(PaymentInfoSelectors.selectIsPaying))),
        filter(([_, isPaying]): boolean => !isPaying),
        concatMap((): Observable<Action> => {
          return this.paymentInfoService.getPaymentHistory().pipe(
            concatMap((response: IAuthResponse) => {
              if (response.data.payments.length === 0) {
                return EMPTY;
              }

              return of(
                PaymentInfoActions.upsertPaymentInfo({
                  paymentInfo: response.data.payments,
                }),
              );
            }),
            catchError((): Observable<Action> => EMPTY),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  handleUpdatedResponse$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handleUpdatedResponse),
        withLatestFrom(this.store.pipe(select(BoostProductSelectors.selectProductUuid))),
        concatMap(([{ updatedPaymentInfo }, boostProductUuid]): Observable<Action> => {
          return this.handleUpdatedPaymentResponse$(updatedPaymentInfo, boostProductUuid);
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  onSuccessfullyPaymentDone$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.setPaymentStatus),
        filter(({ paymentStatus }): boolean => this.paymentInfoService.isPaymentOk(paymentStatus)),
        map((): Action => {
          this.store.dispatch(ConversationActions.cleanConversations());
          this.store.dispatch(MessageActions.cleanMessages());

          return MembershipActions.latestPaidMembershipChange();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  loadPaymentProvider$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.loadPaymentProvider),
        concatMap(() =>
          this.paymentInfoService.loadPaymentProvider().pipe(
            exponentialBackoff(),
            tap({
              next: (response: IAuthResponse<IPaymentProvider>) => {
                if (
                  response.data !== undefined &&
                  response.data.provider !== '' &&
                  response.data.payment_types !== undefined
                ) {
                  this.paymentInfoService.setProvider(response.data.provider ?? PaymentCommon.PROVIDER_PAGSEGURO);
                  this.paymentInfoService.setPaymentTypesAvailable(
                    response.data.payment_types ?? [PaymentMethods.PAYMENT_CREDIT_CARD, PaymentMethods.PAYMENT_BOLETO],
                  );

                  return;
                }

                this.paymentInfoService.setDefaultPaymentConfiguration();
              },
            }),
            catchError(() => {
              this.paymentInfoService.setDefaultPaymentConfiguration();

              return EMPTY;
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  handleAccessToCheckout$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handleAccessToCheckout),
        withLatestFrom(this.store.pipe(select(ProductSelectors.selectMembershipProducts))),
        concatMap(([action, paymentOptions]): Observable<Action> => {
          if (
            action.planPath === ProductPaths.DADDY_PREMIUM_PLUS_1_MONTH &&
            this.planAPlusEligibility.checkEligibility({ user: this.user })
          ) {
            return of(
              ProductV2Actions.fetchSpecificMembershipProduct({
                uuid: ProductVariantIdentifier.DADDY_PREMIUM_PLUS_1_MONTH,
              }),
            );
          }

          if (this.isExpressApprovalProduct(action.variantUuid)) {
            return of(
              PaymentInfoActions.setHasResolvedPlans({
                hasResolvedPlans: true,
              }),
            );
          }

          if (!paymentOptions?.length) {
            this.store.dispatch(ProductV2Actions.fetchMembershipProducts({ overrideCache: true }));

            return of(action).pipe(delay(this.RETRY_ACTION_DELAY));
          }

          if (this.trialService.isTrialProduct(action.variantUuid)) {
            return of(PaymentInfoActions.handleAccessToTrialCheckout());
          }

          return of(
            PaymentInfoActions.navigateAfterResolvingPlans({
              planPath: action.planPath,
              variantUuid: action.variantUuid,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  handleAccessToTrialCheckout$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handleAccessToTrialCheckout),
        concatMap((): Observable<Action> => {
          return of(
            PaymentInfoActions.setHasResolvedPlans({
              hasResolvedPlans: true,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  navigateAfterResolvingPlans$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.navigateAfterResolvingPlans),
        withLatestFrom(this.store.pipe(select(ProductSelectors.selectMembershipProducts))),
        concatMap(([action, paymentOptions]) => {
          if (!this.isValidPlanPath(action.planPath, paymentOptions)) {
            this.router.navigate(['main', 'upgrade-account'], {
              queryParamsHandling: 'merge',
              queryParams: {
                [HAS_ACCESSED_FORBIDDEN_CHECKOUT_QUERY_PARAM_NAME]: 1,
              },
            });

            return EMPTY;
          }

          return of(
            PaymentInfoActions.setHasResolvedPlans({
              hasResolvedPlans: true,
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  resetHasResolvedPlansOnLogin$: Observable<void> = createEffect(
    (): Observable<void> =>
      this.authenticationService.onLogin$.pipe(
        tap({
          next: () => {
            this.store.dispatch(
              PaymentInfoActions.setHasResolvedPlans({
                hasResolvedPlans: false,
              }),
            );
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  handlePixForFailedPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.handlePixForFailedPayment),
        concatLatestFrom(() => this.store.pipe(select(BoostProductSelectors.selectProductUuid))),
        filter(([{ updatedPaymentInfo }, boostProductUuid]) =>
          this.canShowPixAlternativePayment(updatedPaymentInfo, boostProductUuid),
        ),
        map(() => PaymentInfoActions.checkPixAvailability()),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  checkPixAvailability$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentInfoActions.checkPixAvailability),
        concatLatestFrom(() => this.store.pipe(select(PaymentInfoSelectors.selectHasPixTest))),
        filter(([_, hasPixTest]) => hasPixTest),
        tap({
          next: () => {
            this.handlePixAlternativePayment();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  private get user() {
    return this.authenticationService.get();
  }

  public canShowPixAlternativePayment(updatedPaymentInfo: IPaymentInfo, boostProductUuid: string | undefined) {
    return (
      !!updatedPaymentInfo.variant_uuid &&
      !this.isExpressApprovalProduct(updatedPaymentInfo.variant_uuid) &&
      !this.boostProductService.isBoostPackagePayment(updatedPaymentInfo, boostProductUuid)
    );
  }

  public isValidPlanPath(planPath: string, paymentOptions: IPaymentOption[]) {
    return (
      paymentOptions.find(
        (paymentOption: IPaymentOption) =>
          paymentOption.uuid === this.productRouteInfoService.getVariantUuidByPath(planPath),
      ) !== undefined
    );
  }

  private isExpressApprovalProduct(variantUuid: string) {
    return variantUuid === ProductVariantIdentifier.EXPRESS_APPROVAL;
  }

  private handleUpdatedPaymentResponse$(
    updatedPaymentInfo: IPaymentInfo,
    boostProductUuid: string,
  ): Observable<Action | never> {
    this.store.dispatch(
      PaymentInfoActions.setIsPaying({
        isPaying: false,
      }),
    );

    if (this.paymentInfoService.isPaymentInfoStatusFailed(updatedPaymentInfo.status)) {
      return this.handleFailedPayment(updatedPaymentInfo);
    }

    if (this.paymentInfoService.isPaymentInfoStatusApproval(updatedPaymentInfo.status)) {
      return this.handleApprovedPayment(updatedPaymentInfo, boostProductUuid);
    }

    return EMPTY;
  }

  private handleFailedPayment(updatedPaymentInfo: IPaymentInfo): Observable<Action> {
    this.store.dispatch(BoostProductActions.checkIfIsBoostPayment({ updatedPaymentInfo }));
    this.store.dispatch(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus: PaymentStatus.PAYMENT_ERROR,
      }),
    );
    this.store.dispatch(
      PaymentInfoActions.handlePixForFailedPayment({
        updatedPaymentInfo,
      }),
    );

    return of(
      PaymentInfoActions.handlePaymentErrorStatus({
        price: updatedPaymentInfo.subtotal_amount,
      }),
    );
  }

  private handlePixAlternativePayment() {
    this.modalService.confirm(
      'common.payment.failed_retry_or_pix',
      () => {
        this.store.dispatch(PaymentInfoActions.setShowPixAlternativePayment({ showPixAlternativePayment: true }));

        this.store.dispatch(
          PaymentInfoActions.setPaymentStatus({
            paymentStatus: PaymentStatus.PAYMENT_NONE,
          }),
        );
      },
      'common.payment.failed_pix',
      'common.payment.failed_retry',
    );
  }

  private handleApprovedPayment(updatedPaymentInfo: IPaymentInfo, boostProductUuid: string): Observable<Action> {
    this.store.dispatch(BoostProductActions.checkIfIsBoostPayment({ updatedPaymentInfo }));
    this.store.dispatch(
      onPaymentSuccess({
        paymentInfo: updatedPaymentInfo,
      }),
    );

    if (!this.boostProductService.isBoostPackagePayment(updatedPaymentInfo, boostProductUuid)) {
      this.store.dispatch(ProductV2Actions.fetchMembershipProducts({ overrideCache: false }));
      this.profileService.updateSelf();
      this.store.dispatch(PaymentInfoActions.navigateAfterPayment());
    }

    if (updatedPaymentInfo.variant_uuid === ProductVariantIdentifier.EXPRESS_APPROVAL) {
      return of(
        PaymentInfoActions.setPaymentStatus({
          paymentStatus: PaymentStatus.PAYMENT_NONE,
        }),
      );
    }

    return of(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus: PaymentStatus.PAYMENT_OK,
      }),
    );
  }
}
