import { Injectable, Type, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IModalViewCloseByNavigate } from '@libs/components/modal-view/interface/modal-view-close-by-navigate';

import { IModalViewOptions } from '@libs/components/modal-view/interface/modal-view-options';
import { ModalViewActions } from '@libs/store/modal-view';

import { ModalViewService } from '@meupatrocinio/services/modal-view/modal-view.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { concatMap, delay, map, tap } from 'rxjs/operators';

@Injectable()
export class ModalViewEffects {
  private modalViewService = inject(ModalViewService);
  private actions$ = inject(Actions);
  private router = inject(Router);
  private store = inject(Store);

  openModalView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalViewActions.openModalView),
        map(
          ({
            component,
            options,
          }: {
            component: Type<unknown>;
            options: IModalViewOptions;
          }): void => {
            this.openModalView(component, options);
            this.handleModalViewIsOpenend(true);
          },
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  closeModalView$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalViewActions.close),
        tap((): void => {
          this.closeModalView();
          this.handleModalViewIsOpenend(false);
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  closeAndDispatchNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalViewActions.closeAndDispatchNavigate),
        concatMap(({ route }: IModalViewCloseByNavigate) => {
          this.closeModalView();
          this.handleModalViewIsOpenend(false);

          return of(ModalViewActions.navigateAfterClosing({ route })).pipe(delay(450));
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  navigateAfterClosing$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ModalViewActions.navigateAfterClosing),
        tap(({ route }: IModalViewCloseByNavigate): void => {
          this.router.navigate(route);
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  private openModalView(component: Type<unknown>, options: IModalViewOptions): void {
    this.modalViewService.open(component, options);
  }

  private closeModalView(): void {
    this.modalViewService.close();
  }

  private handleModalViewIsOpenend(isModalOpened: boolean): void {
    this.store.dispatch(
      ModalViewActions.setModalOpened({
        isModalOpened,
      }),
    );
  }
}
