<div class="ten-message-wrapper">
    <div class="ten-message-frame clearfix">
        <div class="avatar-header">
            <img
                class="avatar"
                [src]="avatar"
                alt
            >
            <img
                class="icon-badge elite"
                src="/assets/img/elite-badge-only-gold.svg"
                alt
            >
            <img
                class="icon-badge premium"
                src="/assets/img/premium-rounded.svg"
                alt
            >
        </div>
        <h2>
            {{ 'modules.main.shared.ten_message_modal.title' | translate }}
        </h2>
        <p>
            <mp-advanced-translate key="modules.main.shared.ten_message_modal.description">
                <b mp-translate-param="premium">
                    {{ 'modules.main.pages.payment.product_types.premium_member' | translate }}
                </b>
                <b mp-translate-param="elite">
                    {{ 'modules.main.pages.payment.product_types.elite_member' | translate }}
                </b>
                <b mp-translate-param="price">
                    {{ 'modules.main.shared.ten_message_modal.description.price' | translate }}
                </b>
            </mp-advanced-translate>
        </p>
        <a
            class="btn btn-lg btn-upgrade"
            cdkFocusInitial
            (click)="navigateToUpgradeAccount()"
        >
            {{ 'modules.main.shared.ten_message_modal.upgrade' | translate }}
        </a>
        <button
            class="btn btn-link btn-close"
            type="button"
            (click)="closeModal()"
        >
            {{ 'common.not_now' | translate }}
        </button>
    </div>
</div>
