import { OptionalCustomEventParameters } from '@meupatrocinio/infra/netcore/interfaces/custom-events-params.interface';
import { NetcoreCustomEvents } from '@meupatrocinio/infra/netcore/types/custom-events-netcore.type';
import { createActionGroup, props } from '@ngrx/store';

export const NetcoreActions = createActionGroup({
  source: 'Netcore',
  events: {
    'Dispatch Custom Event': props<{ params?: OptionalCustomEventParameters; eventName: NetcoreCustomEvents }>(),
  },
});
