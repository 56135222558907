import { createAction } from '@ngrx/store';

enum Actions {
  INITIALIZE = '[Datadog] - Initialize',
  SET_USER = '[Datadog] - Set User',
  FORCE_SESSION_REPLAY_ON_REG_V5 = '[Datadog] - Force Session Replay on Reg V5',
}

export const initialize = createAction(Actions.INITIALIZE);

export const setUser = createAction(Actions.SET_USER);

export const forceSessionReplayOnRegV5 = createAction(Actions.FORCE_SESSION_REPLAY_ON_REG_V5);
