import { Injectable } from '@angular/core';

import { MembershipType } from '@libs/shared/membership/membership.common';

import { PRODUCT_ROUTES_MAP } from '@meupatrocinio/modules/payment-v2/product-route-info/product-routes-map';

@Injectable({
  providedIn: 'root',
})
export class ProductRouteInfoService {
  public getPathByVariantUuid(planUuid: string) {
    const productRouteInfo = PRODUCT_ROUTES_MAP.find((product): boolean => product.variantUuid === planUuid);

    if (!productRouteInfo) {
      return '';
    }

    return productRouteInfo.path;
  }

  public getVariantUuidByPath(productPath: string) {
    const productRouteInfo = PRODUCT_ROUTES_MAP.find((product): boolean => product.path === productPath);

    if (!productRouteInfo) {
      return '';
    }

    return productRouteInfo.variantUuid;
  }

  public doesPathExists(productPath: string) {
    return PRODUCT_ROUTES_MAP.some((product): boolean => product.path === productPath);
  }

  public getPathByVariantPeriodAndMembershipType(period: number, membershipType: MembershipType) {
    const productRouteInfo = PRODUCT_ROUTES_MAP.find(
      (product): boolean => product.periodCode === period && product.membershipType === membershipType,
    );

    if (!productRouteInfo) {
      return null;
    }

    return productRouteInfo.path;
  }

  public getProductByPlanPath(productPath: string) {
    const productRouteInfo = PRODUCT_ROUTES_MAP.find((product): boolean => product.path === productPath);

    if (!productRouteInfo) {
      return null;
    }

    return productRouteInfo;
  }
}
