import { Injectable, inject } from '@angular/core';
import { MessageCommon } from '@libs/shared/message/message.common';
import { ConversationActions } from '@libs/store/conversations';
import { ExpressApprovalActions } from '@libs/store/express-approval';
import { MainActions } from '@libs/store/main';
import { MeltToastActions } from '@libs/store/melt-toast';
import { MessageSelectors } from '@libs/store/messages';
import { NotificationActions } from '@libs/store/notification';
import { ProfileActions } from '@libs/store/profile/actions';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { SocketService } from '@meupatrocinio/services/socket/socket.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, concatMap, filter, map, tap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private socketService = inject(SocketService);
  private authenticationService = inject(AuthenticationService);
  private downloadManagerService = inject(DownloadManagerService);

  connect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.connect),
        tap({
          next: (): void => {
            this.socketService.connect();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  disconnect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.disconnect),
        tap((): void => this.socketService.disconnect()),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  initialize$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MainActions.userInitialized),
        map((): Action => {
          return this.handleConnection();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  initializeOnExpressApproval$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExpressApprovalActions.initializeExpressApproval),
        map((): Action => {
          return this.handleConnection();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  initializeNotificationListeners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.initializeNotificationListeners),
        tap({
          next: (): void => {
            this.store.dispatch(ConversationActions.watchConversation());
            this.store.dispatch(ConversationActions.watchReadConversation());
            this.store.dispatch(ProfileActions.watchBookmark());
            this.store.dispatch(ProfileActions.watchViewProfile());
            this.store.dispatch(MeltToastActions.watchMelt());
          },
        }),
        map((): Action => NotificationActions.notificationListenersInitialized()),
      ),
    { dispatch: false, useEffectsErrorHandler: false },
  );

  onLogout$ = createEffect(
    () => {
      return this.authenticationService.onLogout$.pipe(map((): Action => NotificationActions.disconnect()));
    },
    { dispatch: true, useEffectsErrorHandler: true },
  );

  onConnectionError$ = createEffect(
    () => {
      return this.socketService.listen('connect_error').pipe(
        map((): Action => {
          return NotificationActions.downloadMessagesRecent();
        }),
      );
    },
    { dispatch: true, useEffectsErrorHandler: true },
  );

  downloadMessagesRecent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationActions.downloadMessagesRecent),
        withLatestFrom(
          this.store.pipe(
            select(MessageSelectors.selectLastMessageIdReceived, {
              profileId: this.authenticationService.get().profile_id,
            }),
          ),
        ),
        filter(([_, lastMessageId]) =>
          MessageCommon.canDownloadMessagesRecent(lastMessageId, this.downloadManagerService),
        ),
        concatMap(([_, lastMessageId]) => {
          return this.downloadManagerService.resetAndUpdate('messagesRecent', lastMessageId).pipe(
            map((): Action => {
              return NotificationActions.messagesRecentDownloaded();
            }),
            catchError(() => EMPTY),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  private handleConnection() {
    this.store.dispatch(NotificationActions.initializeNotificationListeners());

    return NotificationActions.connect();
  }
}
