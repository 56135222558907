import { SugarRole } from '@libs/domains/user/enums/sugar-role.enum';
import { UserIdentification } from '@libs/domains/user/services/user-identification.service';
import { EligibilityContext } from '@libs/modules/product-v2/interfaces/eligibility-context.interface';
import { EligibilityStrategy } from '@libs/modules/product-v2/interfaces/eligibility-strategy.interface';
import { MembershipCommon } from '@libs/shared/membership/membership.common';

export class PlanAPlusEligibility implements EligibilityStrategy {
  public isEligible({ user }: EligibilityContext) {
    const isDaddy = UserIdentification.getSugarRole(user.sex, user.membership_type_id) === SugarRole.SugarDaddy;

    return isDaddy && MembershipCommon.isFree(user.membership_type_id);
  }

  static getStrategy() {
    return new PlanAPlusEligibility();
  }
}
