<div
    class="subscribed-status"
    [class.extended]="!isTinyBadges()"
>
    @if ((canSeeSubscribeButton$ | async) && isFree()) {
      <mp-subscribe-button
        id="menu-bar-button-to-sign"
        (click)="closeSlidableMenu()"
        [theme]="getButtonSizeModifierClass()"
      />
    }
    @if (isTinyBadges()) {
      <mp-membership-badge (click)="openSlidableMenu()"></mp-membership-badge>
    } @else {
      <mp-membership-badge-extended></mp-membership-badge-extended>
    }
</div>
