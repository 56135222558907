import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { PaymentInfoActions, PaymentInfoSelectors } from '@libs/store/payment-info';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CPFValidations } from '@libs/services/payment/validators';
import { UuidGenerator } from '@libs/utils/uuid-generator/uuid-generator';
import { ProviderSwitchActions } from '@meupatrocinio/modules/payment-v2/provider-switch/actions';
import { CPFValidator } from '@meupatrocinio/modules/payment-v2/validators/cpf-validator';
import { IResolvedProductRouteInfo } from '@meupatrocinio/resolvers/payment/interfaces/resolved-product-route-info.interface';

@Component({
  selector: 'mp-boleto-payment-form',
  templateUrl: 'boleto-payment-form.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoletoPaymentFormComponent {
  private formBuilder = inject(UntypedFormBuilder);
  private store = inject(Store);
  private clipboard = inject(Clipboard);

  @Input() isExpressApproval = false;
  @Input() type: MembershipType = 0;
  @Input() period = 0;
  @Input() price = 0;
  @Input() product!: IResolvedProductRouteInfo;
  @Input() productDescription = '';

  public readonly CPF_FORM_CONTROL = 'cpf';
  public boletoFormGroup: UntypedFormGroup = this.formBuilder.group({});
  public isLoading$: Observable<boolean> = of(false);
  public boletoUrlToCopy: Observable<string> = of('');
  public cpfValidator: CPFValidator;

  public cpfValidationMessage = '';

  ngOnInit() {
    this.buildPaymentFormGroup();
    this.store.dispatch(PaymentInfoActions.resetBoletoUrl());
    this.boletoUrlToCopy = this.store.pipe(select(PaymentInfoSelectors.selectBoletoUrl));
    this.isLoading$ = this.store.pipe(select(PaymentInfoSelectors.selectIsPaying));
  }

  public canEnableSubmitButton(isLoading: boolean) {
    return !isLoading && this.boletoFormGroup.valid;
  }

  public copyToClipboard(url: string) {
    this.clipboard.copy(url);
  }

  public makePayment() {
    if (!this.isFormValid()) {
      return;
    }

    const { productUuid, variantUuid } = this.product;
    const idempotencyKey = UuidGenerator.generate();

    this.store.dispatch(
      ProviderSwitchActions.handleBoletoPayment({
        paymentData: {
          productUuid,
          variantUuid,
          taxId: this.cpf,
          idempotencyKey,
          totalPrice: this.price,
        },
      }),
    );
  }

  protected isFormValid() {
    return this.boletoFormGroup.valid;
  }

  protected get cpf() {
    return this.boletoFormGroup.get(this.CPF_FORM_CONTROL).value;
  }

  protected buildPaymentFormGroup() {
    this.boletoFormGroup = this.formBuilder.group({
      [this.CPF_FORM_CONTROL]: ['', CPFValidations.validations.validation],
    });

    const errors = this.boletoFormGroup.get(this.CPF_FORM_CONTROL)?.errors;
    this.cpfValidationMessage = CPFValidations.validations.messages(errors);
  }
}
