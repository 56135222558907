import { MembershipType } from '@libs/shared/membership/membership.common';
import { ProductVariantIdentifier } from '@libs/shared/product/product-variant-identifier.enum';

export const DEFAULT_SELECTED_PLANS: {
  [key: string]: ProductVariantIdentifier;
} = {
  [MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM]: ProductVariantIdentifier.DADDY_PREMIUM_3_MONTHS,
  [MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE]: ProductVariantIdentifier.DADDY_ELITE_3_MONTHS,
  [MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM]: ProductVariantIdentifier.BABY_PREMIUM_3_MONTHS,
};

export const PLAN_A_PLUS_BOOST_CREDITS = 2;
