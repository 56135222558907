<label 
  [htmlFor]="id"
  class="payment-selector"
  [ngClass]="handleDisableModifierClass()"
  tabindex="0"
  (dragstart)="onClick.emit($event)"
  >
  <input 
    type="radio" 
    role="radio"
    [attr.aria-label]="label | translate"
    [id]="id" 
    [name]="inputName" 
    [disabled]="isDisabled" 
    (click)="onClick.emit($event)"
    tabindex="0"
    >
  <div class="payment-selector__container">
    <p>{{ label | translate }}</p>
    <span>{{ price | currency: 'BRL' }}</span>
  </div>
  <div class="payment-selector__illustrations">
      <ul>
        @for (item of images; track $index) {
          <li>
            <img
              [src]="item" 
              alt="icon payment method"
            >
          </li>
        }
      </ul>
    <i 
      class="material-icons" 
      alt="Right arrow"
    >&#XE5E1;</i>
  </div>
</label>
