import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MODAL_DATA } from '@libs/services/modal/modal.tokens';
import { ConversationCommon } from '@libs/shared/conversation/conversation.common';
import { IApplicationState } from '@libs/store/application-state';
import { Store, select } from '@ngrx/store';

import { Router } from '@angular/router';
import { ModalRef } from '@libs/services/modal/modal-ref';
import { MessageActions, MessageSelectors } from '@libs/store/messages';
import { MobileAppSelectors } from '@libs/store/native-app/selectors';
import { UpgradeAccountScreenActions } from '@libs/store/upgrade-account';
import { Config } from '@meupatrocinio/config';
import { IFreeMsgModalData } from '@meupatrocinio/modules/main/shared/free-msg-modal/free-message-modal-data.interface';
import { MembershipService } from '@meupatrocinio/services/membership.service';
import { TrialRuleService } from '@meupatrocinio/services/trial.rule.service';
import { Observable, Subscription, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'mp-free-msg-modal',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './free-msg-modal.html',
})
export class FreeMsgModalComponent {
  private store = inject(Store<IApplicationState>);
  private modalRef = inject(ModalRef);
  private router = inject(Router);
  private membershipService = inject(MembershipService);
  private trialRuleService = inject(TrialRuleService);
  private modalData = inject<IFreeMsgModalData>(MODAL_DATA);

  public isLoading = true;
  public alreadySentFreeMessage = false;
  public freeMsgCount = 0;
  public maximumFreeMessageCount = ConversationCommon.MAX_NUMBER_OF_FREE_CONVERSATIONS;
  public isNativeApp$: Observable<boolean> = this.store.select(MobileAppSelectors.selectIsOnMobileApp);

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .pipe(select(MessageSelectors.alreadySentFreeMessage))
        .subscribe((alreadySentFreeMessage: boolean): void => {
          this.alreadySentFreeMessage = alreadySentFreeMessage;

          if (alreadySentFreeMessage) {
            this.isLoading = false;
          }
        }),
    );
    this.subscriptions.push(this.handleFreeMsgCount().subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    this.subscriptions = [];

    this.store.dispatch(MessageActions.sentFreeMessage({ sentFreeMessage: false }));
  }

  protected handleFreeMsgCount() {
    const loadingTimeForVisualComfort = 500;
    const getFreeMsgCount = (count) => {
      if (count < ConversationCommon.MAX_NUMBER_OF_FREE_CONVERSATIONS) {
        return count + 1;
      }

      return ConversationCommon.MAX_NUMBER_OF_FREE_CONVERSATIONS;
    };

    return of(this.modalData.messagesCount).pipe(
      delay(loadingTimeForVisualComfort),
      tap({
        next: (messagesCount) => {
          this.freeMsgCount = getFreeMsgCount(messagesCount);
          this.isLoading = false;
          this.store.dispatch(
            MessageActions.updateUserAfterFreeMsg({
              freeMsgCount: this.freeMsgCount,
            }),
          );
        },
      }),
    );
  }

  closeModal(): void {
    this.modalRef.close();
  }

  goToUpgradeAccount(): void {
    this.closeModal();

    if (!this.membershipService.isAlreadyPaid()) {
      this.router.navigate(['main', 'premium']);

      return;
    }

    this.store.dispatch(UpgradeAccountScreenActions.navigateToUpgradeAccount());
  }

  canShowGameTrial(): boolean {
    return this.isTrialGameSatisfied() && Config.gameTrial.enableMessageGame;
  }

  isTrialGameSatisfied(): boolean {
    return (
      !this.alreadySentFreeMessage &&
      this.trialRuleService.trialRuleFactory(this.trialRuleService.CONVERSATION_TYPE).satisfied()
    );
  }

  public navigateToUpgradeAccount() {
    this.store.dispatch(UpgradeAccountScreenActions.navigateToUpgradeAccount());
    this.closeModal();
  }
}
