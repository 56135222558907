import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { PaymentInfoActions } from '@libs/store/payment-info';

import { PixActions } from '@meupatrocinio/modules/ab-tests/effects/pix/actions/actions';
import { ABTestsLoaderService } from '@meupatrocinio/modules/ab-tests/services/ab-tests-loader/ab-tests-loader.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable()
export class PixABTestEffects {
  private actions$ = inject(Actions);
  private authenticationService = inject(AuthenticationService);
  private abTestsLoaderService = inject(ABTestsLoaderService);

  initializeOnAuthentication$ = createEffect(
    () =>
      this.authenticationService.onLogin$.pipe(
        map(() => {
          return PixActions.resolveABTestTreatment();
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  resolveAbTestTreatment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PixActions.resolveABTestTreatment),
        concatMap(() => {
          return this.abTestsLoaderService.getPixPaymentTestTreatment$().pipe(
            concatMap((treatment: boolean) => {
              return of(
                PaymentInfoActions.setHasPixTest({
                  hasPixTest: treatment,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );
}
