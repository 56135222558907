import { Injectable } from '@angular/core';

import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@meupatrocinio/config';

import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  public initialize(): void {
    const { datadog, version } = Config;

    datadogRum.init({
      ...datadog,
      version,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }

  public setUser(user: UserCommon) {
    if (!user || !user.profile_id || !user.username) {
      return;
    }

    datadogRum.setUser({
      id: user.profile_id.toString(),
      name: user.username,
    });
  }

  public forceSessionReplay() {
    datadogRum.startSessionReplayRecording({ force: true });
  }
}
