import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { closeSlidableMenu, navigateToReferral } from '@libs/store/slidable-menu/action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mp-user-buttons-referral',
  templateUrl: './user-buttons-referral.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserButtonsReferralComponent {
  private readonly store = inject(Store<IApplicationState>);

  public readonly THIRTY_DAYS_OF_PREMIUM: number = 30;

  getReferralButtonIcon(): string {
    return UserCommon.resolveImageName('refer-icon', '.svg');
  }

  navigateToReferral(): void {
    this.closeSlidableMenu();
    this.store.dispatch(navigateToReferral());
  }

  closeSlidableMenu(): void {
    this.store.dispatch(closeSlidableMenu());
  }
}
