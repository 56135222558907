<div class="new-modal-box full-img-bg">
    <div class="new-modal-main-header absolute">
        <button
            class="modal-close right-btn"
            type="button"
            (click)="closeModal()"
        >
            <img src="/assets/img/close-icon-white.svg">
        </button>
    </div>
    <div>
        <ng-container>
            <p class="title">
                {{ 'modules.main.pages.upgrade_account_web.blurb1' | translate }}
            </p>
            <p class="description">
                {{ 'modules.main.pages.upgrade_account_web.blurb2' | translate }}
            </p>
        </ng-container>
        <div class="line-options">
            <div
                id="sendPicToApproval"
                *ngIf="noPendingOrApprovedPhoto()"
            >
                <label class="file-input">
                    {{ 'modules.main.pages.upgrade_account_web.send_photo' | translate }}
                    <input
                        type="file"
                        [accept]="getAcceptedImageTypes()"
                        (change)="uploadPhoto($event)"
                    >
                </label>
                <p>
                    {{ 'modules.main.pages.upgrade_account_web.ten_free_messages' | translate }}
                </p>
            </div>
            <div
                id="picWaitingApproval"
                *ngIf="hasPendingPhoto()"
            >
                <a
                    class="off"
                    *ngIf="hasPendingPhoto()"
                >
                    {{ 'modules.main.pages.photo_verify.awaiting_approval' | translate }}
                </a>
                <p>
                    {{ 'modules.main.pages.upgrade_account_web.approval_24_hours' | translate }}
                </p>
            </div>
            <div
                id="orTohide"
                *ngIf="!hasApprovedPhoto()"
            >
                {{ 'modules.main.pages.upgrade_account_web.or' | translate }}
            </div>
            <div>
                <a
                  (click)="navigateToUpgradeAccount()"
                >
                    {{ 'modules.main.pages.upgrade_account_web.subscribe_now' | translate }}
                </a>
                <p>
                    {{ 'modules.main.pages.upgrade_account_web.unlimited_messages' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>
