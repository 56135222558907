import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MembershipCommon } from '@libs/shared/membership/membership.common';
import { ScreenSizeHelpers } from '@libs/utils/screen-size-helpers/screen-size-helpers';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

import { SlidableMenuService } from '@meupatrocinio/services/slidable-menu/slidable-menu.service';
import { SubscribeButtonVisibilityService } from '@meupatrocinio/services/subscribe-button-visibility.service';

@Component({
  selector: 'mp-subscribed-status',
  templateUrl: 'subscribed-status.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribedStatusComponent {
  private readonly authenticationService = inject(AuthenticationService);
  private readonly slidableMenu = inject(SlidableMenuService);
  private readonly subscribeButtonVisibilityService = inject(SubscribeButtonVisibilityService);

  @Input() tinyBadges = false;

  public canSeeSubscribeButton$ = this.subscribeButtonVisibilityService.canSeeSubscribeButton$;

  private get user() {
    return this.authenticationService.get();
  }

  public isTinyBadges() {
    return this.tinyBadges;
  }

  public openSlidableMenu() {
    this.slidableMenu.openSlidableMenu();
  }

  public closeSlidableMenu() {
    this.slidableMenu.closeSlidableMenu();
  }

  public getButtonSizeModifierClass() {
    if (!ScreenSizeHelpers.isMobile()) {
      return 'medium';
    }

    return 'small';
  }

  public isFree() {
    if (!this.user?.membership_type_id) {
      return true;
    }

    return MembershipCommon.isFree(this.user?.membership_type_id);
  }
}
