import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, filter, switchMap, tap } from 'rxjs/operators';

import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';

import { TimeToRegisterService } from '@meupatrocinio/modules/time-to-register/service/time-to-register.service';
import { TimeToRegisterActions } from '@meupatrocinio/modules/time-to-register/state/actions';

@Injectable()
export class TimeToRegisterEffects {
  private actions = inject(Actions);
  private timeToRegisterService = inject(TimeToRegisterService);

  createRequest$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(TimeToRegisterActions.createRequest),
        filter(() => !this.timeToRegisterService.isRequestAlreadyCreated()),
        concatMap(() =>
          this.timeToRegisterService.createRequest().pipe(
            exponentialBackoff(),
            tap({
              next: () => {
                this.timeToRegisterService.updateLocalStorage({
                  createRequestSuccessful: true,
                });
              },
            }),
          ),
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  updateRequest$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(TimeToRegisterActions.updateRequest),
        filter(() => this.timeToRegisterService.shouldUpdate()),
        switchMap(({ profileId, endTime }) =>
          this.timeToRegisterService.updateRequest({ profileId, endTime }).pipe(
            exponentialBackoff(),
            tap(() => {
              this.timeToRegisterService.updateLocalStorage({
                updatedEndTime: typeof endTime !== 'undefined',
                updatedProfileId: typeof profileId !== 'undefined',
              });

              if (this.timeToRegisterService.isFinished()) {
                localStorage.removeItem('TimeToRegister');
              }
            }),
          ),
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  updateProfileIdRequestFrictionless$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(TimeToRegisterActions.updateProfileIdRequestFrictionless),
        filter(() => this.timeToRegisterService.shouldUpdateProfileIdFrictionless()),
        switchMap(({ profileId }) =>
          this.timeToRegisterService.updateRequest({ profileId }).pipe(
            exponentialBackoff(),
            tap(() => {
              this.timeToRegisterService.updateLocalStorage({
                updatedProfileId: typeof profileId !== 'undefined',
              });

              if (this.timeToRegisterService.isFinished()) {
                localStorage.removeItem('TimeToRegister');
              }
            }),
          ),
        ),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );
}
