import { IProfile } from '@libs/shared/profile/profile';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ProfileActions = createActionGroup({
  source: 'Profile',
  events: {
    'Favorite Profile': props<{ profile: IProfile }>(),
    'UnFavorite Profile': props<{ profile: IProfile }>(),
    'Watch View Profile': emptyProps(),
    'Watch Bookmark': emptyProps(),
    'Push Notification': props<{ profileId: number }>(),
    'Save Contact Phone': props<{ contactPhone: number }>(),
    'Redirect For WhatsApp': emptyProps(),
  },
});
