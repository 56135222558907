import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, fromEvent, of } from 'rxjs';
import { concatMap, share, take, tap } from 'rxjs/operators';

import { IWebSocketStatus } from '@libs/interfaces/socket/socket.service.interface';
import { ISocketService } from '@libs/shared/interfaces/socket.interface';
import { UiActions, UiSelectors } from '@libs/store/new-ui';

import { Config } from '@meupatrocinio/config';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

import { Socket, io } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService implements ISocketService {
  protected authenticationService = inject(AuthenticationService);
  protected store = inject(Store);

  protected socketIO: Socket = io(Config.serverIp, this.getConfiguration());

  connect(): void {
    if (this.socketIO.connected) {
      return;
    }

    this.socketIO = this.addConnectionSettings();
    this.socketIO.connect();

    this.handleChatUIOnReconnection();
    this.handleChatUIOnReconnectAttempt();
    this.verifyPreviousSocketStatus();
  }

  public verifyPreviousSocketStatus() {
    this.store.pipe(select(UiSelectors.selectWebSocketStatus), take(1)).subscribe({
      next: (webSocketStatus) => {
        if (webSocketStatus.isOffline) {
          this.dispatchWebSocketStatus({
            isReconnected: false,
            isOffline: true,
            isOnline: true,
          });

          return;
        }

        this.dispatchWebSocketStatus({
          isReconnected: false,
          isOffline: false,
          isOnline: true,
        });
      },
    });
  }

  protected handleChatUIOnReconnection() {
    this.socketIO.io.on('reconnect', () => {
      this.dispatchWebSocketStatus({
        isReconnected: true,
        isOffline: false,
        isOnline: true,
      });
    });
  }

  protected handleChatUIOnReconnectAttempt() {
    this.socketIO.io.on('reconnect_attempt', (attempt) => {
      if (attempt === 1) {
        this.dispatchWebSocketStatus({
          isReconnected: false,
          isOffline: true,
          isOnline: false,
        });
      }
    });
  }

  public dispatchWebSocketStatus(webSocketStatus: IWebSocketStatus) {
    this.store.dispatch(
      UiActions.setWebSocketStatus({
        webSocketStatus,
      }),
    );
  }

  disconnect(): void {
    this.socketIO.disconnect();
  }

  listen(eventName: string): Observable<string> {
    return of(this.socketIO).pipe(
      concatMap((socket: Socket): Observable<string> => {
        return fromEvent(socket, `${eventName}`);
      }),
      share(),
      tap({
        next: (socketMessage: string): void => {
          this.debugEvents(eventName, socketMessage);
        },
      }),
    );
  }

  listenUserEvent(eventName: string): Observable<string> {
    return this.listen(`${this.authenticationService.get().profile_id}${eventName}`);
  }

  addConnectionSettings(): Socket {
    return io(Config.serverIp, this.getConfiguration());
  }

  protected debugEvents(description: string, socketMessage: string): void {
    if (!Config.showLogs.notifications) {
      return;
    }

    const date: Date = new Date();
    const timeString =
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds() + '.' + date.getMilliseconds();

    const totalLength: string = ` ${(socketMessage.length / 1024).toFixed(2)} Kb.`;

    console.log(`[${timeString}] NOTIFICATIONS --> ${description}. ${totalLength}`);
  }

  protected getConfiguration(): object {
    return {
      secure: true,
      reconnection: true,
      reconnectionDelayMax: 5000,
      transports: ['websocket'],
      path: '/notifications/v2',
      autoConnect: false,
      query: {
        profileId: this.authenticationService.get().profile_id,
        token: this.authenticationService.getToken(),
      },
    };
  }
}
