import { Action, createReducer, on } from '@ngrx/store';

import * as ConversationActions from '@libs/store/conversations/actions';
import { IConversation } from '@libs/store/conversations/interface';
import { ConversationAdapter, ConversationInitialState, IConversationState } from '@libs/store/conversations/state';

const conversationReducer = createReducer(
  ConversationInitialState,
  on(
    ConversationActions.upsertConversations,
    (state: IConversationState, { conversations }: { conversations: IConversation[] }): IConversationState => {
      return ConversationAdapter.upsertMany(conversations, {
        ...state,
        isLoading: false,
      });
    },
  ),
  on(
    ConversationActions.selectConversation,
    (state: IConversationState, { peerId }: { peerId: number }): IConversationState => {
      return { ...state, selectedId: peerId };
    },
  ),
  on(
    ConversationActions.deleteConversation,
    (state: IConversationState, { conversation }: { conversation: IConversation }): IConversationState => {
      return ConversationAdapter.removeOne(conversation.peer_id, state);
    },
  ),
  on(
    ConversationActions.loadUnreadConversations,
    (state: IConversationState, { unreadConversations }: { unreadConversations: number }): IConversationState => {
      return { ...state, unreadConversations };
    },
  ),
  on(ConversationActions.cleanConversations, (state: IConversationState): IConversationState => {
    return ConversationAdapter.removeAll({
      ...state,
      isLoading: true,
    });
  }),
  on(
    ConversationActions.setIsDownloadingConversations,
    (state: IConversationState, { isDownloading }): IConversationState => {
      return { ...state, isDownloading };
    },
  ),
);

export function reducer(state: IConversationState, action: Action): IConversationState {
  return conversationReducer(state, action);
}
