import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';

import { COOKIES_TO_BE_UPDATED } from '@libs/modules/cookies/domain/cookies';
import { CookieType } from '@libs/shared/interfaces/cookie-fake-data.interface';
import { CookiesActions } from '@libs/store/cookies';

import { Config } from '@meupatrocinio/config';
import { IMarketingCookies } from '@meupatrocinio/modules/marketing-cookies/interfaces/marketing-cookies.interface';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class MarketingCookiesService {
  constructor(
    protected store: Store,
    protected authHttpService: AuthHttpService,
    protected cookieService: CookieService,
    protected activatedRoute: ActivatedRoute,
  ) {
    //
  }

  public getMarketingCookiesAndDispatchUpdateAction() {
    const cookies: IMarketingCookies = {};

    for (const cookieName of COOKIES_TO_BE_UPDATED) {
      const cookieValue = this.cookieService.get(cookieName);

      if (cookieValue.length > 0) {
        cookies[cookieName] = cookieValue;
      }
    }

    if (Object.keys(cookies).length === 0) {
      return;
    }

    this.store.dispatch(CookiesActions.updateMarketingCookies(cookies));
  }

  public updateMarketingCookies$(utm_source?: string, utm_campaign?: string, gclid?: string, vcid?: string) {
    const endpoint: string = `${Config.serverIp}cookies/update`;

    const marketingCookiesDataObject: IMarketingCookies = {
      utm_source: utm_source,
      utm_campaign: utm_campaign,
      gclid: gclid,
      vcid: vcid,
    };

    this.deleteAlreadyUpdatedCookie();

    this.saveUpdatedCookies(marketingCookiesDataObject);

    return this.authHttpService.post(endpoint, marketingCookiesDataObject);
  }

  public getRegV5Cookies() {
    const params = this.activatedRoute.snapshot.queryParams;
    const marketingCookies = {};

    COOKIES_TO_BE_UPDATED.map((cookieName) => {
      if (!params[cookieName]) {
        return;
      }

      marketingCookies[cookieName] = params[cookieName];
    });

    return marketingCookies;
  }

  public saveDefaultMarketingCookies(params: Params) {
    this.handleSavingMarketingCookies(params, COOKIES_TO_BE_UPDATED);
  }

  public saveMarketingDataAsCookie(cookieName: string, params: Params) {
    if (
      this.hasAlreadyUpdatedCookie(cookieName, params) ||
      params[cookieName] === undefined ||
      params[cookieName] === ''
    ) {
      return;
    }

    this.setCookie(cookieName, params[cookieName]);
  }

  protected hasAlreadyUpdatedCookie(cookieName: string, params: Params) {
    return params[cookieName] !== '' && this.getUpdatedCookiesValues(cookieName).includes(params[cookieName]);
  }

  protected deleteAlreadyUpdatedCookie() {
    for (const cookieName of COOKIES_TO_BE_UPDATED) {
      this.cookieService.delete(cookieName, '/');
    }
  }

  protected saveUpdatedCookies(cookiesToBeUpdated: IMarketingCookies) {
    for (const key in cookiesToBeUpdated) {
      if (cookiesToBeUpdated[key] === undefined) {
        continue;
      }

      const updatedCookies = this.getUpdatedCookiesValues(key);

      if (updatedCookies.includes(cookiesToBeUpdated[key])) {
        continue;
      }

      updatedCookies.push(cookiesToBeUpdated[key]);

      this.setCookie(`updated-${key}`, JSON.stringify(updatedCookies));
    }
  }

  protected getUpdatedCookiesValues(cookieName: string) {
    const serializedUpdatedCookies = this.cookieService.get(`updated-${cookieName}`);

    if (serializedUpdatedCookies === '') {
      return [];
    }

    return JSON.parse(serializedUpdatedCookies);
  }

  protected handleSavingMarketingCookies(params: Params, cookiesToCheck: string[]) {
    for (const cookie in params) {
      if (!cookiesToCheck.includes(cookie)) {
        continue;
      }

      this.saveMarketingDataAsCookie(cookie, params);
    }
  }

  protected setCookie(name: string, value: string) {
    this.cookieService.set(name, value, {
      expires: 0,
      path: '/',
      domain: undefined,
      secure: false,
      sameSite: CookieType.TYPE_LAX,
    });
  }
}
