import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of, timer } from 'rxjs';

import { VersionCheckingActions } from '@libs/store/version-checking';
import { Config } from '@meupatrocinio/config';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { catchError, concatMap, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class VersionCheckingEffects {
  private actions$ = inject(Actions);
  private httpClient = inject(HttpClient);
  private router = inject(Router);
  private authenticationService = inject(AuthenticationService);

  private readonly noAutoRefreshRoutes: readonly string[] = Object.freeze([
    '/main/upgrade-account',
    '/main/payment',
    '/main/conversation',
    '/main/boost',
    '/register/waiting-list',
  ]);
  private readonly autoRefreshCycleDuration: number = 300000;

  startVersionCheckingCycle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VersionCheckingActions.startVersionCheckingCycle),
        switchMap(() => {
          return timer(this.autoRefreshCycleDuration, this.autoRefreshCycleDuration).pipe(
            concatMap(() => {
              return of(VersionCheckingActions.checkVersion());
            }),
            takeUntil(this.authenticationService.onLogout$),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: true },
  );

  checkVersion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VersionCheckingActions.checkVersion),
        concatMap(() => {
          return this.requestNewestVersion().pipe(
            concatMap((response: HttpResponse<string>) => {
              if (
                typeof response.body === 'undefined' ||
                this.isVersionEmpty() ||
                !this.isOutdated(response.body, this.getVersion())
              ) {
                return EMPTY;
              }

              return of(VersionCheckingActions.reloadApplication());
            }),
            catchError(() => {
              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  reloadApplication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VersionCheckingActions.reloadApplication),
        filter((): boolean => this.shouldReload()),
        tap({
          next: (): void => this.refreshBrowser(),
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  private requestNewestVersion(): Observable<HttpResponse<string>> {
    return this.httpClient.get(Config.v2URL + 'version.txt', {
      responseType: 'text',
      observe: 'response',
    });
  }

  private isVersionEmpty(): boolean {
    return Config.version === '';
  }

  private getVersion(): string {
    return Config.version;
  }

  private shouldReload(): boolean {
    return !this.noAutoRefreshRoutes.some((noRefreshRoute: string): boolean => {
      return this.router.url.includes(noRefreshRoute);
    });
  }

  private isOutdated(latestVersion: string, userVersion: string): boolean {
    const parsedLatestVersion: string = latestVersion.replace(/v|\.dev|\s/g, '');
    const parsedUserVersion: string = userVersion.replace(/v|\.dev|\s/g, '');

    return parsedUserVersion !== parsedLatestVersion;
  }

  public refreshBrowser(): void {
    location.reload();
  }
}
