import { Injectable, OnDestroy, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

import { CPFValidations, getNestableControl } from '@libs/services/payment/validators';

import { AuthenticationService } from '@meupatrocinio/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentModalPixFormService implements OnDestroy {
  private formBuilder = inject(FormBuilder);
  private authenticationService = inject(AuthenticationService);

  public readonly CPF: string = 'cpf';
  public paymentGroupForm: FormGroup = this.formBuilder.group({
    [this.CPF]: ['', CPFValidations.validations.validation],
  });
  private readonly subscription: Subscription = new Subscription();

  ngOnInit() {
    this.subscribeToOnLogout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public getFormGroup() {
    return this.paymentGroupForm;
  }

  public getCPF() {
    const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

    if (control === null) {
      return '';
    }

    return control.value.toString();
  }

  public setCPF(cpf: string) {
    const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

    if (control === null) {
      return;
    }

    control.setValue(cpf);
    control.updateValueAndValidity();
  }

  public isFormValid() {
    return this.paymentGroupForm.valid;
  }

  public isCPFValid() {
    const control: AbstractControl | null = this.getFormGroup().get(this.CPF);

    if (control === null) {
      return false;
    }

    return control.valid || control.pristine;
  }

  public clearAllData() {
    this.getFormGroup().reset({
      [this.CPF]: '',
    });
  }

  public getFormValidationErrosMessage(controlName: string) {
    const fieldsTovalidate = {
      [this.CPF]: CPFValidations.validations.messages,
    };

    if (fieldsTovalidate[controlName] === undefined) {
      return '';
    }

    const errors: ValidationErrors = getNestableControl(controlName, this.paymentGroupForm).errors;

    return fieldsTovalidate[controlName](errors);
  }

  private subscribeToOnLogout() {
    this.subscription.add(
      this.authenticationService.onLogout$.subscribe({
        next: (): void => {
          this.clearAllData();
        },
      }),
    );
  }
}
