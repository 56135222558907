import { createAction, props } from '@ngrx/store';

import { ITrialModalData, ITrialModalOnce } from '@libs/shared/trial/interface';
import { Trial } from '@libs/shared/trial/trial';

enum Actions {
  OPEN_TRIAL_MODAL = '[Trial] - Open Trial Modal',
  INITIALIZE_TRIAL_ONCE_MODAL = '[Trial] - Initialize Trial Once Modal',
}

export const initializeTrialOnceModal = createAction(
  Actions.INITIALIZE_TRIAL_ONCE_MODAL,
  props<{ trialProps: ITrialModalOnce }>(),
);

export const openTrialModal = createAction(
  Actions.OPEN_TRIAL_MODAL,
  props<{ trial: Trial; modalData: ITrialModalData }>(),
);
