import { Injectable, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

import { Config } from '@meupatrocinio/config';
import { ReportFormControlName } from '@meupatrocinio/modules/main/modals/report-modal/enum/report-form-control-names';
import { IReportErrorResponse } from '@meupatrocinio/modules/main/modals/report-modal/interface/report-error-response.interface';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable()
export class ReportService {
  private formBuilder = inject(UntypedFormBuilder);
  private authHttp = inject(AuthHttpService);

  public reportForm: UntypedFormGroup = this.formBuilder.group({});

  public setupReportForm(): void {
    this.reportForm = this.formBuilder.group({
      [ReportFormControlName.REASON]: ['', [Validators.required]],
    });
  }

  public getReportFormInstance(): UntypedFormGroup {
    return this.reportForm;
  }

  public report$(
    profileId: number,
    reason: string,
    successCallback: () => void,
    errorCallback: (error: IReportErrorResponse) => void,
  ): Observable<IAuthResponse> {
    const endpoint: string = `${Config.serverIp}profile/${profileId}/report`;

    return this.authHttp.post(endpoint, { reason }).pipe(
      tap({
        next: (): void => successCallback(),
        error: (error: IReportErrorResponse): void => errorCallback(error),
      }),
    );
  }
}
