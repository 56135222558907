import { Injectable } from '@angular/core';
import { NetcoreActions } from '@meupatrocinio/infra/netcore/actions/netcore.actions';
import { OptionalCustomEventParameters } from '@meupatrocinio/infra/netcore/interfaces/custom-events-params.interface';
import { Store } from '@ngrx/store';

@Injectable()
export class NetcoreFacadeService {
  constructor(protected store: Store) {
    //
  }

  public profilePreview(profileId: number) {
    this.store.dispatch(
      NetcoreActions.dispatchCustomEvent({
        params: { profile_id: profileId },
        eventName: 'PROFILE_VIEW',
      }),
    );
  }

  public allPlansView() {
    this.store.dispatch(
      NetcoreActions.dispatchCustomEvent({
        eventName: 'ALL_PLANS_VIEW',
      }),
    );
  }

  public planView(price: number) {
    this.store.dispatch(
      NetcoreActions.dispatchCustomEvent({
        params: { price },
        eventName: 'PLAN_VIEW',
      }),
    );
  }

  public subscriptionPurchase(price: number, paymentMethod: OptionalCustomEventParameters['payment_method']) {
    this.store.dispatch(
      NetcoreActions.dispatchCustomEvent({
        params: { price, payment_method: paymentMethod },
        eventName: 'SUBSCRIPTION_PURCHASE',
      }),
    );
  }

  public favoriteProfile(profileId: number) {
    this.store.dispatch(
      NetcoreActions.dispatchCustomEvent({
        params: { profile_id: profileId },
        eventName: 'FAVORITE_PROFILE',
      }),
    );
  }

  public requestPrivatePhoto(profileId: number) {
    this.store.dispatch(
      NetcoreActions.dispatchCustomEvent({
        params: { profile_id: profileId },
        eventName: 'REQUEST_PRIVATE_PHOTO',
      }),
    );
  }
}
