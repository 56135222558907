import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { MenuActionsComponentCommon } from '@libs/modules/main/shared/menu-actions/menu-action.component.common';
import { UserCommon } from '@libs/shared/user/user.common';

import { Config } from '@meupatrocinio/config';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { SlidableMenuService } from '@meupatrocinio/services/slidable-menu/slidable-menu.service';

@Component({
  selector: 'mp-menu-actions',
  templateUrl: './menu-actions.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MenuActionsComponent extends MenuActionsComponentCommon {
  protected readonly URL_FAQ: string = Config.supportLink + '?search=';

  constructor(
    protected auth: AuthenticationService,
    protected slidableMenuService: SlidableMenuService,
    protected modalService: ModalService,
    protected router: Router,
  ) {
    super();
  }

  navigateToEditProfile(): void {
    this.closeSlidableMenu();
    this.router.navigate(['main', 'edit-profile']);
  }

  navigateToSettings(): void {
    this.closeSlidableMenu();
    this.router.navigate(['main', 'settings']);
  }

  getUrlToCustomerSupport(): string {
    if (UserCommon.isDaddyMommy(this.auth.get())) {
      return `${this.URL_FAQ}daddy`;
    }

    return `${this.URL_FAQ}baby`;
  }

  logout(): void {
    this.closeSlidableMenu();
    this.modalService.confirm('common.confirm_logout', (): void => {
      this.auth.logout();
    });
  }

  closeSlidableMenu(): void {
    this.slidableMenuService.closeSlidableMenu();
  }
}
