import { EligibilityContext } from '@libs/modules/product-v2/interfaces/eligibility-context.interface';
import { EligibilityStrategy } from '@libs/modules/product-v2/interfaces/eligibility-strategy.interface';

export class SpecialProductsEligibility {
  private strategy: EligibilityStrategy;

  constructor(strategy: EligibilityStrategy) {
    if (!strategy) {
      throw new Error('An Eligibility Strategy is required.');
    }

    this.strategy = strategy;
  }

  public checkEligibility(context: EligibilityContext) {
    return this.strategy.isEligible(context);
  }
}
