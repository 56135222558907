import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { BoostPackagesActions } from '@libs/store/boost-packages';
import { PaymentInfoActions } from '@libs/store/payment-info';

import { Config } from '@meupatrocinio/config';
import { AnalyticsService } from '@meupatrocinio/services/analytics/analytics.service';
import { GlobalObjectService } from '@meupatrocinio/services/global-object-service';
import { ModalService } from '@meupatrocinio/services/modal.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentFlowHandlersService {
  constructor(
    protected analyticsService: AnalyticsService,
    protected globalObjectService: GlobalObjectService,
    protected modalService: ModalService,
    protected store: Store,
    protected translateService: TranslateService,
  ) {
    //
  }

  public handlePaymentAttemptInitialActions({
    paymentProvider,
    value,
    product,
    productDescription,
  }: {
    paymentProvider: string;
    value: number;
    product: string;
    productDescription: string;
  }) {
    this.analyticsService.push({
      event: 'mp_payment_attempt',
      product,
      payment_provider: paymentProvider,
      payment_method: 'credit_card',
      value,
      product_description: productDescription,
    });
    this.setIsPaying(true);
    this.setPaymentStatus(PaymentStatus.PAYMENT_PROCESSING);
  }

  public clientErrorBeforePayment() {
    this.analyticsService.push({
      event: 'mp_payment_client_error',
    });
    this.modalService.open(this.translateService.instant('modules.payment_v2.network_error_before_payment'));

    return this.disablePaymentProcessing();
  }

  protected disablePaymentProcessing() {
    this.setPaymentStatus(PaymentStatus.PAYMENT_NONE);
    this.setIsPaying(false);

    return of(
      BoostPackagesActions.setIsPaymentProcessing({
        isPaymentProcessing: false,
      }),
    );
  }

  public handleEncryptionError() {
    this.analyticsService.push({
      event: 'mp_payment_encryption_error',
    });
    this.modalService.open(this.translateService.instant('modules.payment_v2.encryption_error'));

    return this.disablePaymentProcessing();
  }

  public handleCreatePaymentError() {
    this.analyticsService.push({
      event: 'mp_payment_error',
    });
    this.modalService.open(this.translateService.instant('modules.payment_v2.network_error_on_payment'));

    return this.disablePaymentProcessing();
  }

  public handleTokenizationError() {
    this.analyticsService.push({
      event: 'mp_payment_tokenization_error',
    });
    this.modalService.open(this.translateService.instant('modules.payment_v2.network_error_before_payment'));

    return this.disablePaymentProcessing();
  }

  public handleBoletoGenerationError(error: unknown) {
    if (Config.development) {
      console.log(error);
    }

    this.setIsPaying(false);
    this.modalService.open(this.translateService.instant('common.unknown_error'));
  }

  public handleBoletoGenerationSuccess(response: IAuthResponse<{ url: string }>) {
    if (!response.data || !response.data.url) {
      throw new Error('Empty response data');
    }

    const boletoUrl = response.data.url;

    this.store.dispatch(
      PaymentInfoActions.setBoletoUrl({
        boletoUrl,
      }),
    );
    this.openBoleto(boletoUrl);
    this.analyticsService.push({
      event: 'mp_boleto_generation_success',
    });
    this.setIsPaying(false);
  }

  private openBoleto(url: string): void {
    this.globalObjectService.window.open(url, '_blank');
  }

  public setIsPaying(isPaying: boolean) {
    this.store.dispatch(
      PaymentInfoActions.setIsPaying({
        isPaying,
      }),
    );
  }

  public setPaymentStatus(paymentStatus: PaymentStatus) {
    this.store.dispatch(
      PaymentInfoActions.setPaymentStatus({
        paymentStatus,
      }),
    );
  }

  public handleQRCodeGenerationSuccess(qrCode: string, copyCode: string) {
    this.store.dispatch(
      PaymentInfoActions.setQrCode({
        qrCode,
      }),
    );
    this.store.dispatch(
      PaymentInfoActions.setCopyCode({
        copyCode,
      }),
    );
    this.setIsPaying(false);
  }

  public handleQRCodeError() {
    this.setIsPaying(false);
    this.setPaymentStatus(PaymentStatus.PAYMENT_ERROR);
  }
}
