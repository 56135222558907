import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { SupportParamsAction } from '@libs/store/ui/actions/support-params.action';
import { isNotNull } from '@libs/utils/generic-checkers/generic-checkers';
import { CustomerSupportService } from '@meupatrocinio/services/customer-support.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mp-customer-support',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customer-support.html',
  styleUrls: ['./customer-support.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerSupportComponent implements AfterViewInit, OnDestroy {
  private customerSupportService = inject(CustomerSupportService);
  private router = inject(Router);
  private modalService = inject(ModalService);
  private store = inject(Store);
  private changeDetectorReference = inject(ChangeDetectorRef);
  private renderer2 = inject(Renderer2);

  @ViewChild('reputationElement', { static: true })
  reputationElement: ElementRef<HTMLDivElement>;

  public title = '';
  public issue = '';
  public loading = false;
  public messageSent = false;
  private reputationElementScript: HTMLScriptElement = {} as HTMLScriptElement;
  private supportParams: any;
  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.subscriptions.push(
      this.store
        .select('uiState')
        .pipe(take(1))
        .subscribe((uiState): void => {
          this.supportParams = uiState.supportParams;
          this.store.dispatch(new SupportParamsAction({}));
        }),
    );
  }

  ngAfterViewInit(): void {
    this.initializeReputationElement();
  }

  ngOnDestroy(): void {
    this.destroyReputationElement();
    this.subscriptions.forEach((subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public initializeReputationElement(): void {
    this.reputationElementScript = this.renderer2.createElement('script');
    this.renderer2.setAttribute(this.reputationElementScript, 'type', 'text/javascript');
    this.renderer2.setAttribute(this.reputationElementScript, 'id', 'ra-embed-reputation');
    this.renderer2.setAttribute(
      this.reputationElementScript,
      'data-id',
      'UnBJQ19MVFFPdVF6MGpqVTptZXUtcGF0cm9jaW5pbw==',
    );
    this.renderer2.setAttribute(this.reputationElementScript, 'data-target', 'reputation-ra');
    this.renderer2.setAttribute(this.reputationElementScript, 'data-model', '2');
    this.renderer2.setAttribute(
      this.reputationElementScript,
      'src',
      'https://s3.amazonaws.com/raichu-beta/selos/bundle.js',
    );

    this.renderer2.appendChild(this.reputationElement.nativeElement, this.reputationElementScript);
  }

  public destroyReputationElement(): void {
    this.renderer2.removeChild(this.reputationElement.nativeElement, this.reputationElementScript);
    this.reputationElementScript = {} as HTMLScriptElement;
  }

  goBack(): void {
    this.router.navigate(['main', 'settings']);
  }

  sendIssue(): void {
    const title: string = this.title;
    const message: string = this.issue;
    if (!this.verifyFields(title, message)) {
      this.modalService.open('common.all_fields_required');
      return;
    }

    this.loading = true;
    this.send(
      title,
      message,
      (): void => {
        this.loading = false;
        this.messageSent = true;
        this.markForChangeDetection();
      },
      (): void => {
        this.loading = false;
        this.modalService.open('common.unknown_error');
        this.markForChangeDetection();
      },
    );
  }

  private markForChangeDetection(): void {
    this.changeDetectorReference.markForCheck();
  }

  send(title: string, message: string, successCallback: () => void, errorCallback: (error: any) => void): void {
    this.customerSupportService.sendSupportRequest(title, message, this.supportParams, successCallback, errorCallback);
  }

  verifyFields(title: string, message: string): boolean {
    return isNotNull(title) && isNotNull(message);
  }
}
