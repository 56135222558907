import { Injectable } from '@angular/core';
import { Gender } from '@libs/domains/user/enums/gender.enum';
import { SugarRole } from '@libs/domains/user/enums/sugar-role.enum';
import { MembershipType } from '@libs/shared/membership/membership.common';

@Injectable({
  providedIn: 'root',
})
export class UserIdentification {
  public static getSugarRole(aGender: Gender, aMembershipTypeId: MembershipType) {
    const sugarRoles: { [gender: number]: { [membershipTypeId: number]: SugarRole } } = {
      [Gender.Male]: {
        [MembershipType.MEMBERSHIP_TYPE_BABY_FREE]: SugarRole.SugarBabyMale,
        [MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM]: SugarRole.SugarBabyMale,
        [MembershipType.MEMBERSHIP_TYPE_DADDY_FREE]: SugarRole.SugarDaddy,
        [MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM]: SugarRole.SugarDaddy,
        [MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE]: SugarRole.SugarDaddy,
      },
      [Gender.Female]: {
        [MembershipType.MEMBERSHIP_TYPE_BABY_FREE]: SugarRole.SugarBabyFemale,
        [MembershipType.MEMBERSHIP_TYPE_BABY_PREMIUM]: SugarRole.SugarBabyFemale,
        [MembershipType.MEMBERSHIP_TYPE_DADDY_FREE]: SugarRole.SugarMommy,
        [MembershipType.MEMBERSHIP_TYPE_DADDY_PREMIUM]: SugarRole.SugarMommy,
        [MembershipType.MEMBERSHIP_TYPE_DADDY_ELITE]: SugarRole.SugarMommy,
      },
    };

    const aGenderOrUndefined = sugarRoles[aGender];

    if (aGenderOrUndefined === undefined) {
      throw new Error(`Invalid gender value: ${aGender}. Expected one of: ${Object.values(Gender).join(', ')}`);
    }

    const aSugarRoleOrUndefined = aGenderOrUndefined[aMembershipTypeId];

    if (aSugarRoleOrUndefined === undefined) {
      throw new Error(`Invalid membership type ${aMembershipTypeId} for gender ${aGender}`);
    }

    return aSugarRoleOrUndefined;
  }
}
