import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PaymentMethods } from '@libs/modules/main/pages/payment/payment-methods';
import { PaymentCommon, PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { UserCommon } from '@libs/shared/user/user.common';
import { IApplicationState } from '@libs/store/application-state';
import { UiActions } from '@libs/store/new-ui';
import { PaymentActions } from '@libs/store/payment';
import { IPaymentInfo, PaymentInfoStatus } from '@libs/store/payment-info';
import { IPaymentProvider } from '@libs/store/payment/interfaces/payment-provider';

export abstract class PaymentInfoServiceCommon {
  public readonly MIN_HOUR_TO_DISPLAY_UPGRADE_BUTTON_FOR_BABIES: number = 48;

  constructor(protected store: Store<IApplicationState>) {
    //
  }

  public isPaymentOk(paymentStatus: PaymentStatus): boolean {
    return paymentStatus === PaymentStatus.PAYMENT_OK;
  }

  public isNonElitePaymentOk(paymentStatus: PaymentStatus, user: UserCommon): boolean {
    return !UserCommon.isPendingElite(user) && this.isPaymentOk(paymentStatus);
  }

  public isPaymentAwaiting(paymentStatus: PaymentStatus): boolean {
    return paymentStatus === PaymentStatus.PAYMENT_AWAITING;
  }

  public isPaymentError(paymentStatus: PaymentStatus): boolean {
    return paymentStatus === PaymentStatus.PAYMENT_ERROR;
  }

  public isPaymentProcessing(paymentStatus: PaymentStatus): boolean {
    return paymentStatus === PaymentStatus.PAYMENT_PROCESSING;
  }

  public isPaymentDone(paymentStatus: PaymentStatus): boolean {
    return (
      paymentStatus !== PaymentStatus.PAYMENT_PROCESSING &&
      paymentStatus !== PaymentStatus.PAYMENT_ERROR &&
      paymentStatus !== PaymentStatus.PAYMENT_NONE
    );
  }

  public isPaymentInfoStatusFailed(status: PaymentInfoStatus): boolean {
    return status === PaymentInfoStatus.StatusFailed;
  }

  public isPaymentInfoStatusApproval(status: PaymentInfoStatus): boolean {
    return status === PaymentInfoStatus.StatusApproval;
  }

  protected itContainsFirstPayment(latestStoredPaymentInfo: IPaymentInfo, updatedPaymentInfo: IPaymentInfo): boolean {
    return Object.keys(latestStoredPaymentInfo).length === 0 && Object.keys(updatedPaymentInfo).length > 0;
  }

  public abstract loadPaymentProvider(): Observable<IAuthResponse<IPaymentProvider>>;

  public setDefaultPaymentConfiguration() {
    this.setProvider(PaymentCommon.PROVIDER_PAGSEGURO);
    this.setPaymentTypesAvailable([PaymentMethods.PAYMENT_CREDIT_CARD, PaymentMethods.PAYMENT_BOLETO]);
  }

  public setProvider(provider: string) {
    this.store.dispatch(PaymentActions.setSelectedProvider(provider));
    this.store.dispatch(
      UiActions.setHasLoadedPaymentProvider({
        hasLoadedPaymentProvider: true,
      }),
    );
  }

  public setPaymentTypesAvailable(paymentTypes: string[]) {
    this.store.dispatch(
      PaymentActions.setProviderPaymentTypes({
        payment_types: paymentTypes,
      }),
    );
  }
}
