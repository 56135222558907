export enum ProductVariantIdentifier {
  EXPRESS_APPROVAL = '4c1cdbbb-1df5-45e0-bb13-db8d10e533f9',

  TRIAL_DADDY_PREMIUM_7_DAYS = '08c34900-7226-4cbb-a03b-6f5920088f80',
  TRIAL_DADDY_PREMIUM_7_DAYS_ALTERNATIVE = '8c39958b-3711-40c6-abcb-2a9dae029a61',

  DADDY_PREMIUM_15_DAYS = 'ad0ff771-9a47-4b79-bc71-d328811d34bc',
  DADDY_PREMIUM_1_MONTH = '78d1f94d-e5cb-4894-bd4b-3322ed10fac9',
  DADDY_PREMIUM_1_MONTH_ALTERNATIVE = '7a2e4e10-0d62-4111-a757-ca9388d18c2e',
  DADDY_PREMIUM_3_MONTHS = 'dc26c188-e2ac-4283-a085-43b98a06cf3a',
  DADDY_PREMIUM_6_MONTHS = '4db6917f-4703-4637-aceb-545be1739726',

  DADDY_PREMIUM_PLUS_1_MONTH = 'fc748487-3d7f-4b2d-8e55-f13e453b0057',

  DADDY_ELITE_1_MONTH = '35c95102-8630-4f5f-aee1-bed41e801036',
  DADDY_ELITE_3_MONTHS = '44ddf0e9-ca53-4adb-8cd0-e3a24b7858d5',
  DADDY_ELITE_6_MONTHS = '11c56322-6e5b-4c07-b6de-ee378bef0fe9',

  BABY_PREMIUM_1_MONTH = '4bfd5c53-d604-4e90-8308-668936ca0879',
  BABY_PREMIUM_3_MONTHS = '052f9989-4ce5-4226-bd47-459087d6ea95',
  BABY_PREMIUM_6_MONTHS = 'b25e3021-700c-4aaa-a400-b19264c1d9ed',
}
