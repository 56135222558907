import { Injectable, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IApplicationState } from '@libs/store/application-state';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber, Subscription, of } from 'rxjs';

import { DeactivateReasonActions, DeactivateReasonSelectors, IReason } from '@libs/store/deactivate-reason';
import { AnalyticsService } from '@meupatrocinio/services/analytics/analytics.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { ModalService } from '@meupatrocinio/services/modal.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';
import { TrialService } from '@meupatrocinio/services/trial/trial.service';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeactivateAccountService implements OnDestroy {
  private readonly store = inject(Store<IApplicationState>);
  private readonly trialService = inject(TrialService);
  private readonly translate = inject(TranslateService);
  private readonly profileService = inject(ProfileService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly modalService = inject(ModalService);
  private readonly router = inject(Router);
  private readonly analyticsService = inject(AnalyticsService);

  private subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  private get user() {
    return this.authenticationService.get();
  }

  showDeactivatedSuccessModal(): void {
    this.modalService.open('modules.main.pages.visibility.disable.success', this.logout);
  }

  showDeactivateErrorModal(): void {
    this.modalService.open('modules.main.pages.visibility.disable.error');
  }

  protected handleLastModalAnswer(reason: string): Observable<unknown> {
    return new Observable((subscriber: Subscriber<unknown>): void => {
      this.modalService.confirm(
        'modules.main.pages.visibility.disable.modal.confirm',
        (): void => {
          this.deactivateAccountRequest(reason);
          subscriber.complete();
        },
        'modules.main.pages.visibility.disable_account',
        'modules.main.pages.visibility.disable.modal.confirm.no',
        (): void => {
          this.router.navigate(['main', 'home']);
          subscriber.complete();
        },
      );
    });
  }

  handleDisableAccount(disableReasonValue: number, disableDescription: string): void {
    this.subscriptions.push(
      this.store
        .pipe(
          select(DeactivateReasonSelectors.selectReasonByValue, {
            value: disableReasonValue,
          }),
          map((reasonValue: IReason | undefined): string => {
            let reason: string = disableDescription;

            if (reasonValue !== undefined) {
              reason = this.translate.instant(`modules.main.pages.visibility.disable_reasons.${reasonValue.label}`);
            }

            return reason;
          }),
          tap((reason: string): void => {
            this.handleDeactivateAccountReason(reason);
          }),
          take(1),
        )
        .subscribe(),
    );
  }

  protected handleDeactivateAccountReason(reason: string): void {
    if (!this.trialService.isElectiveToDeactivateModal(this.user)) {
      this.deactivateAccountRequest(reason);
      return;
    }

    this.store.dispatch(
      DeactivateReasonActions.setCurrentDeactivateReason({
        currentReasonTextSelected: reason,
      }),
    );
    this.startModalGame();
  }

  startModalGame(): void {
    this.analyticsService.push({
      event: 'deactivate_modal_trial',
      profile_id: this.user?.profile_id,
    });
    this.trialService.startGamefiedTrial({
      firstTimer: 0,
      satisfied: (): boolean => !this.trialService.getGameTrial().isEmpty(),
      retryStrategy: {
        retryWhen: (): boolean => false,
        millisecondsRetry: 0,
      },
      trial: this.trialService.getGameTrial(),
      modalData: {
        declineCallback: this.userDeclinedOffer,
        successCallback: this.userAcceptedOffer,
        title: 'modules.main.pages.visibility.disable.modal.trial_title',
        buttonText: 'modules.main.pages.visibility.disable.modal.trial_button',
      },
    });
  }

  deactivateAccountRequest = (reason: string): void => {
    this.subscriptions.push(
      this.profileService.deactivateSelf(reason).subscribe(this.deactivateAccountSuccess, this.deactivateAccountError),
    );
  };

  userAcceptedOffer = (): void => {
    this.store.dispatch(
      DeactivateReasonActions.setCurrentDeactivateReason({
        currentReasonTextSelected: null,
      }),
    );
  };

  userDeclinedOffer = (): void => {
    this.subscriptions.push(
      this.store
        .pipe(
          select(DeactivateReasonSelectors.selectCurrentReason),
          switchMap((reason: string | null): Observable<unknown> => {
            if (reason === null) {
              return of({});
            }

            return this.handleLastModalAnswer(reason);
          }),
          take(1),
        )
        .subscribe(),
    );
  };

  deactivateAccountSuccess = (): void => {
    this.store.dispatch(
      DeactivateReasonActions.setCurrentDeactivateReason({
        currentReasonTextSelected: null,
      }),
    );

    this.showDeactivatedSuccessModal();
  };

  deactivateAccountError = (): void => {
    this.store.dispatch(
      DeactivateReasonActions.setCurrentDeactivateReason({
        currentReasonTextSelected: null,
      }),
    );
    this.showDeactivateErrorModal();
  };

  logout = (): void => {
    this.authenticationService.logout();
  };
}
