import { Injectable, inject } from '@angular/core';
import { UserCommon } from '@libs/shared/user/user.common';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscribeButtonVisibilityService {
  private readonly authenticationService = inject(AuthenticationService);

  public canSeeSubscribeButton$ = this.authenticationService.onLogin$.pipe(
    startWith(false),
    switchMap(() => of(this.authenticationService.get())),
    filter((user) => {
      return user?.membership_type_id !== undefined && user?.profiletype !== undefined;
    }),
    map((user) => {
      return user.profiletype !== UserCommon.USER_CATEGORY_4;
    }),
    shareReplay(1),
  );
}
