import { createAction } from '@ngrx/store';

export const CLOSE_SLIDABLE_MENU_ACTION: string = '[Slidable Menu] - Close Slidable Menu Action';
export const OPEN_SLIDABLE_MENU_ACTION: string = '[Slidable Menu] - Open Slidable Menu Action';
export const NAVIGATE_TO_REFERRAL_ACTION: string = '[Slidable Menu] - Navigate To Referral Action';

export const closeSlidableMenu = createAction(CLOSE_SLIDABLE_MENU_ACTION);

export const openSlidableMenu = createAction(OPEN_SLIDABLE_MENU_ACTION);

export const navigateToReferral = createAction(NAVIGATE_TO_REFERRAL_ACTION);
