import { IUpgradeAccountSelectedPlans } from '@libs/store/upgrade-account/interfaces/upgrade-account-selected-plans.interface';
import { IUpgradeAccountState } from '@libs/store/upgrade-account/interfaces/upgrade-account-state.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const upgradeAccountFeature = createFeatureSelector<IUpgradeAccountState>('upgradeAccountScreen');

export const selectAll = createSelector(upgradeAccountFeature, (state: IUpgradeAccountState): IUpgradeAccountState => {
  return state;
});

export const selectIsResolvingVisiblePlans = createSelector(
  upgradeAccountFeature,
  (state: IUpgradeAccountState): boolean => {
    return state.isResolvingVisiblePlans;
  },
);

export const selectSelectedPlans = createSelector(
  upgradeAccountFeature,
  (state: IUpgradeAccountState): IUpgradeAccountSelectedPlans => {
    return state.selectedPlans;
  },
);
