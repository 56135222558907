<div class="payment-modal-qr-code-result">
    <ng-container *ngIf="!shouldHideQrCode; else expirationWarning">
        <div class="payment-modal-qr-code-result__content">
            <div class="payment-modal-qr-code-result__block">
                <h3 class="payment-modal-qr-code-result__block-title">
                    {{ 'modules.main.shared.boost.component.boost-payment-modal.qr-code' | translate }}
                </h3>
                <div class="payment-modal-qr-code-result__qr-code-block">
                    <app-blurhash-image
                        [imageUrl]="qrCode"
                        [height]="'230px'"
                        [width]="'230px'"
                        [isQrCode]="true"
                    ></app-blurhash-image>
                </div>
            </div>
            <div class="form-group tw-mb-0">
                <fieldset class="form-field feedback">
                    <label for="copyCode"
                        >{{ 'modules.main.shared.boost.component.boost-payment-modal.copy-code' | translate }}
                    </label>
                    <input
                        id="copyCode"
                        type="text"
                        [value]="truncateCopyCode(copyCode)"
                        name="copyCodeField"
                        readonly="readonly"
                        (click)="copyToClipboard(copyCode)"
                    />
                    <button
                        class="copy-button"
                        aria-label="Copy code"
                        (click)="copyToClipboard(copyCode)"
                    >
                        <span class="material-icons" aria-hidden="true">content_copy</span>
                    </button>
                </fieldset>
                <button class="mp-main-button bt-md full-width gray-borderless" type="submit" disabled>
                    {{ 'modules.main.shared.boost.component.boost-payment-modal.button-warning' | translate: { time: time$ | async } }}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-template #expirationWarning>
        <div class="payment-modal-qr-code-result__content">
            <div class="payment-modal-qr-code-result__block">
                <h3 class="payment-modal-qr-code-result__expired-title">
                    {{ 'modules.main.shared.boost.component.boost-payment-modal.expired-qr-code-title' | translate }}
                </h3>
                <p>
                    {{ 'modules.main.shared.boost.component.boost-payment-modal.expired-qr-code-description' | translate }}
                </p>
            </div>
            <button class="mp-main-button bt-md full-width bt-shadow" type="submit" (click)="goToQrCodeGeneration()">
                {{ 'modules.main.shared.boost.component.boost-payment-modal.btn-generate-new-qr-code' | translate }}
            </button>
            <div class="payment-modal-qr-code-result__support">
                <a
                    [routerLink]="['/main', 'settings', 'customer-support']"
                    (click)="handleClose()"
                >
                    {{ 'modules.main.shared.boost.component.boost-payment-modal.support-link' | translate }}
                </a>
            </div>
        </div>
    </ng-template>
</div>
