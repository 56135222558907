import { Directive, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { UserCommon } from '@libs/shared/user/user.common';
import { UserProfileSelectors } from '@libs/store/authentication';

@Directive()
export class MembershipBadgeComponentCommon {
  protected store = inject(Store);

  public user$ = this.store.pipe(select(UserProfileSelectors.selectUser));

  public isPremium(user: UserCommon): boolean {
    return UserCommon.isDaddyMommyPremium(user) || UserCommon.isBabyPremium(user) || UserCommon.isPendingElite(user);
  }

  public isElite(user: UserCommon): boolean {
    return UserCommon.isElite(user);
  }
}
