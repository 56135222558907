<div class="free-msg-modal-box">
    <div class="free-msg-modal-box-main-header">
        <span>
            {{ 'modules.main.shared.free_msg_modal.title' | translate }}
        </span>
        <button
            class="modal-close right-btn"
            type="button"
            (click)="closeModal()"
        >
            <img src="/assets/img/close-icon.svg">
        </button>
        <div
            class="loader-modal"
            *ngIf="isLoading"
        ></div>
        <ng-container *ngIf="!isLoading">
            <div *ngIf="alreadySentFreeMessage">
                <p>
                    {{ 'modules.main.shared.free_msg_modal.free_messages_limit' | translate }}
                </p>
            </div>
            <div *ngIf="!alreadySentFreeMessage">
                <p>
                    <mp-advanced-translate key="modules.main.shared.free_msg_modal.description">
                        <b mp-translate-param="qty">
                            {{ 'modules.main.shared.free_msg_modal.description.qty' | translate:{ numMsgs: freeMsgCount > maximumFreeMessageCount ? maximumFreeMessageCount : freeMsgCount } }}
                        </b>
                    </mp-advanced-translate>
                </p>
            </div>
        </ng-container>
    </div>
    <div class="free-msg-modal-box-main-content">
        <p>
            {{ 'modules.main.shared.free_msg_modal.become_premium_elite' | translate }}
        </p>
        @if (!(isNativeApp$ | async)) {
        <a
            cdkFocusInitial
            (click)="navigateToUpgradeAccount()"
        >
            {{ 'modules.main.pages.upgrade_account_web.subscribe_now' | translate }}
        </a>
        }
    </div>
</div>
