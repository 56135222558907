<mp-payment-modal-pix-form
  *ngIf="canShowPixPaymentOptionContent(
      copyCode$ | async,
      shouldHideQrCode$ | async
  )"
  [submitButtonTranslation]="'modules.main.pages.payment.generate_qr_code'"
  (cpfData)="makePixPayment($event)"
  [showLoading]="isLoadingPixQrCode$ | async"
></mp-payment-modal-pix-form>
<mp-payment-modal-qr-code-result
  *ngIf="canShowPixQrCodeResult(
      qrCode$ | async,
      copyCode$ | async,
      shouldHideQrCode$ | async
  )"
  [qrCode]="qrCode$ | async"
  [copyCode]="copyCode$ | async"
  [shouldHideQrCode]="shouldHideQrCode$ | async"
  [qrCodeGenerationTimestamp]="qrCodeGenerationTimestamp$ | async"
  (onNavigateAwayFromResult)="resetPixData()"
  (onClose)="onClosing()"
></mp-payment-modal-qr-code-result>
