<mp-actionbar>
    <mp-actionbar-back-title [title]="getMembershipNameWithDuration()"></mp-actionbar-back-title>
</mp-actionbar>
<div class="payment__header">
    <span class="payment__header__description">
        {{ getMembershipNameWithDuration() }}
    </span>
    <span class="payment__header__price">
        {{ getPlanPrice(price) }}
    </span>
    @if (hasPrize) {
      <span>
        + {{ planPrizeTitle }}
      </span>
    }
    <span class="payment__header__conditions">
        {{ getPlanConditions(price) }}
    </span>
</div>
