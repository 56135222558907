import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserCommon } from '@libs/shared/user/user.common';
import { UpgradeAccountScreenActions } from '@libs/store/upgrade-account';

import { PaymentInfoService } from '@meupatrocinio/modules/main/services/payment/payment-info.service';
import { SubscribeButtonTheme } from '@meupatrocinio/modules/shared/subscribe-button/subscribe-button-theme.type';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'mp-subscribe-button',
  templateUrl: 'subscribe-button.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SubscribeButtonComponent {
  @Input() theme: SubscribeButtonTheme = 'medium';
  @Input() hideOnMediumDown = false;

  public readonly BUTTON_BASE_CLASSES = 'subscribe-button subscribe-button--';

  constructor(
    protected authenticationService: AuthenticationService,
    public paymentInfoService: PaymentInfoService,
    protected store: Store,
  ) {
    //
  }

  public getSubscribeText() {
    if (this.isBabyFree()) {
      return 'modules.main.shared.home_menu.be_premium';
    }

    if (this.isDaddyMommyFree()) {
      return 'common.subscribe';
    }

    return 'common.upgrade';
  }

  public navigateToUpgradeAccount() {
    this.store.dispatch(UpgradeAccountScreenActions.navigateToUpgradeAccount());
  }

  protected get user() {
    return this.authenticationService.get();
  }

  protected isBabyFree() {
    return UserCommon.isBabyFree(this.user);
  }

  protected isDaddyMommyFree() {
    return UserCommon.isDaddyMommyFree(this.user);
  }

  public isEligibleToSubscribe() {
    return UserCommon.isEligibleToSubscribe(this.user);
  }

  public getSubscribeButtonText() {
    if (this.isBabyFree()) {
      return 'common.subscribe-baby-premium';
    }

    if (this.isDaddyMommyFree()) {
      return 'common.subscribe';
    }

    return 'common.upgrade';
  }

  public getButtonClasses() {
    return `${this.BUTTON_BASE_CLASSES}${this.theme}`;
  }
}
