<ng-container *ngIf="!isSaving; else submittingForm">
    <button
        type="submit"
        class="mp-main-button"
        [disabled]="shouldDisable"
    >
        {{ text | translate }}
    </button>
</ng-container>
<ng-template #submittingForm>
    <mp-saving-step-feedback></mp-saving-step-feedback>
</ng-template>
