@if (!(isNativeApp$ | async)) {
    <div class="boost-nagbar">
        <div class="boost-nagbar__header">
            <img
                class="boost-nagbar__profile-photo"
                *ngFor="let profile of profiles$ | async; let i = index"
                [src]="getProfilePicture(profile)"
                [alt]="'modules.main.shared.boost_nagbar.alt' | translate"
            >
            <img
                class="boost-nagbar__boost-icon"
                src="/assets/img/boost-icon.svg"
                [alt]="'common.boost_icon' | translate"
            >
        </div>
        <div class="boost-nagbar__body">
            <h1 class="boost-nagbar__title">
                <span> {{ 'common.boost_icon' | translate }} </span>
                <br>
                {{ 'modules.main.shared.boost_nagbar.title' | translate }}
            </h1>
            <p class="boost-nagbar__subtitle">
                {{ getNagbarSubtitle() | translate }}
            </p>
            <button class="boost-button mp-main-button bt-shadow bt-sm boost-purple-regular" (click)="navigateToBoostRoute()">
                {{ 'modules.main.shared.boost_nagbar.button' | translate }}
            </button>
        </div>
    </div>
}
